import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { completeReopenLoadLine, deleteLoadLine, updateLoadLine } from "../common/DataLayer"
import { ILoadItem, ILoadLine } from "../common/Interfaces"
import { columnsReceipts } from "../common/Config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faTruck } from "@fortawesome/free-solid-svg-icons"
import '../App.css';


const LoadItemDisplay = (props: { info: ILoadLine, readOnly: boolean, callBack: (line: ILoadLine) => void }) => {
    const [loadLine, setLoadLine] = useState(props.info);
    const [details, setDetails] = useState(props.info.DetailItems);
    const [updating, setUpdating] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean | undefined>(props.info.Status === 979680001 ? true : false);

    const [editing, setEditing] = useState(false);
    const [lineUpdating, setlineUpdating] = useState(false);
    const [completingLine, setCompletingLine] = useState(false);


    const [tsNo, setTsNo] = useState(props.info.TSNo);
    const [from, setFrom] = useState(props.info.From);
    const [to, setTo] = useState(props.info.To);

    async function updateDetail() {
        setUpdating(true);
        const test = await updateLoadLine(loadLine);
        if (test) {
            setlineUpdating(true);
            setUpdating(false);
            setEditing(false);
            setTimeout(() => {
                setlineUpdating(false);
            }, 1000);
        }

    }

    async function deleteDetail() {
        if (props.info.LoadSheetId && props.info.ReceiptId) {
            setDeleting(true);
            const test = await deleteLoadLine(props.info.LoadSheetId, props.info.ReceiptId);
            if (test) {

                setIsDeleted(true);
            }
            setDeleting(false);
        }
    }

    function onDetailChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target.id.split(":");
        const id = target[1];
        const field = target[0];
        let det = details;
        if (det && event.target.value !== undefined) {
            if (field.includes("description")) {
                det.find(x => x.id === id)!.Description = event.target.value ? event.target.value.toLocaleUpperCase() : ""
            }
            else if (field.includes("quantity")) {
                det.find(x => x.id === id)!.Quantity = !Number.isNaN(parseInt(event.target.value)) && event.target.value ? parseInt(event.target.value) : 0;
            }
            else if (field.includes("loaded")) {
                det.find(x => x.id === id)!.QuantityLoaded = !Number.isNaN(parseInt(event.target.value)) && event.target.value ? parseInt(event.target.value) : 0;
            }
            else if (field.includes("weight")) {
                det.find(x => x.id === id)!.Weight = !Number.isNaN(parseInt(event.target.value)) && event.target.value ? parseInt(event.target.value) : 0;
            }
            else if (field.includes("totalcubic")) {
                det.find(x => x.id === id)!.TotalCubic = !Number.isNaN(parseInt(event.target.value)) && event.target.value ? parseInt(event.target.value) : 0;
            }
            else if (field.includes("seal")) {
                det.find(x => x.id === id)!.SealNo = event.target.value ? event.target.value.toLocaleUpperCase() : "";
            }

            const ll = loadLine;
            if (det.find(x => x.id === id)!.QuantityLoaded === det.find(x => x.id === id)!.Quantity) {
                det.find(x => x.id === id)!.Completed = true;
            }
            ll.DetailItems = det;
            setDetails([...det]);
            setLoadLine(ll);

        }
    }
    function onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        let ll = loadLine;
        if (event.target.id.includes("tsno")) {
            setTsNo(event.target.value.toLocaleUpperCase());
            ll.TSNo = event.target.value.toLocaleUpperCase();
        }
        else if (event.target.id.includes("from")) {
            setFrom(event.target.value.toLocaleUpperCase());
            ll.From = event.target.value.toLocaleUpperCase();
        }
        else if (event.target.id.includes("to")) {
            setTo(event.target.value.toLocaleUpperCase());
            ll.To = event.target.value.toLocaleUpperCase();
        }
        setLoadLine(ll);
    }
    function copyClipboard() {
        const tsNo = `\n${props.info.TSNo}`;
        let from = `${props.info.From}`;
        const to = `${props.info.To}`;
        const quantity = props.info.DetailItems?.map(i => i.Quantity).join('\n') ?? '';
        const desc = props.info.DetailItems?.map(i => i.Description).join('\n') ?? '';
        const weight = props.info.DetailItems?.map(i => i.Weight).join('\n') ?? '';
        const cubic = props.info.DetailItems?.map(i => i.TotalCubic).join('\n') ?? '';
        from = from.replace('\n\n', '\n');

        const details = props.info.DetailItems;
        const grid = createGrid(tsNo, from, to, quantity, desc, weight, cubic);
        const tabSeparatedString = gridToExcelString(grid);

        navigator.clipboard.writeText(tabSeparatedString);

    }
    function createGrid(tsNo: string, from: string, to: string, quantity: string, desc: string, weight: string, cubic: string) {
        const tsNoArray = tsNo.split('\n');
        const fromArray = from.split('\n');
        const toArray = to.split('\n');
        const quantityArray = quantity.split('\n');
        const descArray = desc.split('\n');
        const weightArray = weight.split('\n');
        const cubicArray = cubic.split('\n');

        const rowCount = Math.max(tsNoArray.length, fromArray.length, toArray.length);
        const grid = [];

        for (let i = 0; i < rowCount; i++) {
            const row = [
                tsNoArray[i] ? tsNoArray[i].trim() : '',
                fromArray[i] ? fromArray[i].trim() : '',
                toArray[i] ? toArray[i].trim() : '',
                quantityArray[i] || '',
                descArray[i] || '',
                weightArray[i] || '',
                cubicArray[i] || '',
            ];
            grid.push(row);
        }

        return grid;
    }
    function gridToExcelString(grid: string[][]) {
        let excelString = '';

        for (let i = 0; i < grid.length; i++) {
            const row = grid[i];
            excelString += row[0] + '\t' + row[1] + '\t\t' + row[2] + '\t\t' + row[3] + '\t' + row[4] + '\t\t' + row[5] + '\t' + row[6] + (i < grid.length - 1 ? '\n' : ' ');
        }

        return excelString;
    }

    function completeReceipt() {
        if (props.info.LoadSheetId && props.info.ReceiptId) {
            setCompletingLine(true);
            setlineUpdating(true);
            completeReopenLoadLine(props.info.LoadSheetId, props.info.ReceiptId, !completed).then((res) => {
                setTimeout(() => {
                    setCompleted(res);
                    setlineUpdating(false);
                    setCompletingLine(false);
                }, 1000);

            });
        }
    }

    return (<React.Fragment>
        <div style={{ borderWidth: 1, borderColor: "#FFFFFF", backgroundColor: completed ? "#C9F5C9" : "#FFFFFF" }}>
            <Row >
                {
                    editing && !isDeleted ?
                        <>
                            <Col xs={12} md={2}>
                                <TextField
                                    id={"tsno:" + props.info.id}
                                    disabled={props.readOnly}
                                    fullWidth
                                    label="TS NO"
                                    multiline
                                    maxRows={5}
                                    value={tsNo}
                                    onChange={onTextChange}
                                    variant="filled"
                                />
                            </Col>
                            <Col xs={12} md={2}>
                                <TextField
                                    id={"from:" + props.info.id}
                                    disabled={props.readOnly}
                                    fullWidth
                                    label="From"
                                    multiline
                                    maxRows={5}
                                    value={from}
                                    onChange={onTextChange}
                                    variant="filled"
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <TextField
                                    id={"to:" + props.info.id}
                                    disabled={props.readOnly}
                                    fullWidth
                                    label="To"
                                    multiline
                                    maxRows={5}
                                    value={to}
                                    onChange={onTextChange}
                                    variant="filled"
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                {
                                    details?.map((item, index) => {
                                        return <Row style={{ paddingBottom: 15 }}>
                                            <Col xs={12} md={6}>
                                                <TextField
                                                    id={"description:" + item.id}
                                                    disabled={props.readOnly}
                                                    fullWidth
                                                    label="Description"
                                                    multiline
                                                    maxRows={5}
                                                    value={item.Description}
                                                    onChange={onDetailChange}
                                                    variant="filled"
                                                />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <TextField
                                                    id={"weight:" + item.id}
                                                    disabled={props.readOnly}
                                                    fullWidth
                                                    label="Weight"
                                                    multiline
                                                    maxRows={5}
                                                    value={item.Weight}
                                                    onChange={onDetailChange}
                                                    variant="filled"
                                                />
                                                <TextField
                                                    id={"totalcubic:" + item.id}
                                                    disabled={props.readOnly}
                                                    fullWidth
                                                    label="Total Cubic"
                                                    multiline
                                                    maxRows={5}
                                                    value={item.TotalCubic}
                                                    onChange={onDetailChange}
                                                    variant="filled"
                                                />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <TextField
                                                    id={"quantity:" + item.id}
                                                    disabled={props.readOnly}
                                                    fullWidth
                                                    label="Qty"
                                                    multiline
                                                    maxRows={5}
                                                    value={item.Quantity}
                                                    onChange={onDetailChange}
                                                    variant="filled"
                                                />
                                                <TextField
                                                    id={"loaded:" + item.id}
                                                    fullWidth
                                                    label="Loaded Qty"
                                                    multiline
                                                    maxRows={5}
                                                    value={item.QuantityLoaded}
                                                    onChange={onDetailChange}
                                                    variant="filled"
                                                />
                                            </Col>
                                        </Row>
                                    })
                                }
                            </Col>
                        </> : !isDeleted ?
                            <>
                                <Col xs={12} md={1} >
                                    <span className="verySmallText">
                                        <pre>{tsNo}</pre>
                                    </span>
                                    <Button
                                        variant="contained"
                                        about="Copy to clipboard"
                                        aria-label="Copy to clipboard"
                                        startIcon={
                                            <FontAwesomeIcon color="#FFFFFF" icon={faCopy} />
                                        }
                                        onClick={copyClipboard}
                                    />
                                    {/* &nbsp;&nbsp;

                                    <Button
                                        disabled={completingLine}
                                        variant="outlined"
                                        startIcon={
                                            <FontAwesomeIcon color={completed ? "green" : "#000000"} icon={faTruck} />
                                        }
                                        onClick={completeReceipt} /> */}
                                </Col>
                                <Col xs={12} md={3}>
                                    <span id={"from" + loadLine.id} className="smallText">
                                        <pre>{from}</pre>
                                    </span>
                                </Col>
                                <Col xs={12} md={3}>
                                    <span id={"to" + loadLine.id} className="smallText">
                                        <pre>{to}</pre>
                                    </span>
                                </Col>
                                <Col xs={12} md={4}>
                                    {
                                        details?.map((item, index) => {
                                            return <Row style={{ paddingBottom: 15, borderBottom: "3px solid rgb(212, 212, 212)" }}>
                                                <Col xs={12} md={8}>
                                                    <span id={"qty" + loadLine.id} className="smallText">
                                                        <pre>{item.Quantity} - {item.Description}</pre>
                                                    </span>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <pre>
                                                        <span id={"weight" + loadLine.id} className="smallText">Weight: {item.Weight ? item.Weight : ""}</span> <br />
                                                        <span id={"cubic" + loadLine.id} className="smallText">Cubic:  {item.TotalCubic ? item.TotalCubic : ""}</span>
                                                    </pre>
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </Col>
                            </>
                            :
                            <Alert severity={"error"} sx={{ fontSize: 20 }}>
                                <AlertTitle>{"Removed"}</AlertTitle>
                            </Alert>
                }
                <Col xs={5} md={1} style={{ textAlign: "center" }} >
                    {editing && !isDeleted ? <>
                        <Box sx={{ m: 1, position: "relative" }}>
                            <Button variant="text" onClick={updateDetail} disabled={updating}>
                                Update
                            </Button>
                            {updating && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Box>
                    </>
                        : !isDeleted ? <>
                            <Button variant="text" onClick={() => setEditing(true)} disabled={updating}>
                                Edit
                            </Button>
                            <Button variant="text" onClick={() => setShowDeleteDialog(true)} disabled={deleting}>
                                Remove
                            </Button>
                            <Dialog open={showDeleteDialog} >
                                <DialogContent>
                                    <div>
                                        <Alert severity={"warning"} sx={{ fontSize: 16 }}>Are you sure you would like to remove this receipt?</Alert>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setShowDeleteDialog(false)}>No</Button>
                                    <Box sx={{ m: 1, position: "relative" }}>
                                        <Button variant="text" onClick={deleteDetail}>
                                            Yes
                                        </Button>
                                        {deleting && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </DialogActions>
                            </Dialog>
                        </> : ""
                    }
                </Col>
                <Col>
                    {lineUpdating ?
                        <Alert severity={"success"} sx={{ fontSize: 20 }}>
                            <AlertTitle>{"Updated"}</AlertTitle>
                        </Alert>
                        : ""}
                </Col>
            </Row >
        </div>

    </React.Fragment >)


}

export default LoadItemDisplay