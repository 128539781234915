import DynamicsWebApi from "dynamics-web-api";
import { IDetail, ILoadLine, ILoadSheet, IReceipt } from "./Interfaces";
import _ from "lodash";

let apiConfig: DynamicsWebApi;

function getUrl() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "https://tifdbtest.crm3.dynamics.com/";
  } else {
    return Xrm.Utility.getGlobalContext().getClientUrl;
  }
}

// export function createConfig(token: string | undefined) {
//     try {
//         const url = getUrl();
//         if (token && (!process.env.NODE_ENV || process.env.NODE_ENV === "development")) {
//             apiConfig = new DynamicsWebApi({
//                 webApiVersion: '9.1',
//                 webApiUrl: getUrl() + '/api/data/v9.1/',
//                 onTokenRefresh: acquireTokenAPI
//             });
//         }
//         else {
//             console.log('prod api config ' + url);
//             apiConfig = new DynamicsWebApi({ webApiVersion: '9.1' });
//         }
//     }
//     catch (e) {
//         alert(e);
//     }
// }

// async function acquireTokenAPI(dynamicsWebApiCallback: (arg0: AccessTokenResponse) => void) {
//     // authProvider.logout();
//     const tkn = await authProvider.getAccessToken();
//     dynamicsWebApiCallback(tkn);
// }

// export async function acquireToken() {
//     const tkn = await authProvider.getAccessToken();
//     return tkn;
// };

export async function executeFetchXML(entity: string, fetchXML: string) {
  const res = apiConfig
    .executeFetchXml(entity, fetchXML)
    .then((response: any) => {
      // console.log(JSON.stringify(response));
      return response;
    })
    .catch(function (error) {
      console.log(error.message);
    });
  return res;
}

export async function getRoles(userId: string) {
  const filter = "systemuserid eq " + userId;
  const res = await apiConfig
    .retrieveAll("systemuserrolescollection", ["roleid"], filter)
    .then((response: any) => {
      return response;
    })
    .catch(function (error) {
      throw error;
    });
  if (res && res.value.length >= 1) {
    return res.value;
  } else {
    return undefined;
  }
}

export async function getRoleName(roleId: string) {
  const filter = "roleid eq " + roleId;
  const res = await apiConfig
    .retrieveAll("roles", ["name"], filter)
    .then((response: any) => {
      return response;
    })
    .catch(function (error) {
      throw error;
    });
  if (res && res.value.length === 1) {
    return res.value[0]["name"];
  } else {
    return undefined;
  }
}

export async function upsertReceipt(rec: IReceipt) {
  const validate: IReceipt = {
    ReceiptId: checkString(rec.ReceiptId),
    ReceiptNo: checkString(rec.ReceiptNo),
    ReceiptDate: checkString(rec.ReceiptDate),
    ShipFirstName: checkString(rec.ShipFirstName),
    ShipLastName: checkString(rec.ShipLastName),
    ShipEmail: checkString(rec.ShipEmail),
    ShipPhone: checkString(rec.ShipPhone),
    ShipAdd1: checkString(rec.ShipAdd1),
    ShipAdd2: checkString(rec.ShipAdd2),
    ShipCity: checkString(rec.ShipCity),
    ShipProv: checkString(rec.ShipProv),
    ShipPostal: checkString(rec.ShipPostal),
    ShipCountry: checkString(rec.ShipCountry),

    ConFirstName: checkString(rec.ConFirstName),
    ConLastName: checkString(rec.ConLastName),
    ConEmail: checkString(rec.ConEmail),
    ConPhone: checkString(rec.ConPhone),
    ConAdd1: checkString(rec.ConAdd1),
    ConAdd2: checkString(rec.ConAdd2),
    ConCity: checkString(rec.ConCity),
    ConProv: checkString(rec.ConProv),
    ConPostal: checkString(rec.ConPostal),
    ConCountry: checkString(rec.ConCountry),

    TotalPieces: checkNumber(rec.TotalPieces),
    TotalCubic: checkNumber(rec.TotalCubic),
    TotalWeight: checkNumber(rec.TotalWeight),

    PickupDriver: checkString(rec.PickupDriver),
    DoorToDoor: rec.DoorToDoor ? true : false,
    Pickup: checkNumber(rec.Pickup),
    Freight: checkNumber(rec.Freight),
    TotalCharge: checkNumber(rec.TotalCharge),
    Collect: checkNumber(rec.Collect),
    Prepaid: checkNumber(rec.Prepaid),
    AdditionalInsurance: checkNumber(rec.AdditionalInsurance),
    AcceptInsurance: rec.AcceptInsurance ? true : false,
    AgreeTerms: rec.AgreeTerms ? true : false,
    Status: rec.Status ? rec.Status : 10,
    SignatureDate: checkString(rec.SignatureDate),
    Signature: checkString(rec.Signature),
    MimeType: checkString(rec.MimeType),
    Type: checkString(rec.Type),
  };
  try {
    let ret = await fetch(
      "https://prod-28.canadacentral.logic.azure.com:443/workflows/517dfa12145e43eda957bdb4676fa099/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=I4jZ6oJYKU2EFb13i_ObMjdEypEbpNB2DGUWqBPkq5g",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(validate),
      }
    ).then(async (r) => {
      return await r.json();
    });
    return ret.ReceiptId;
  } catch (e) {
    throw e;
  }
}

export async function upsertDetail(det: IDetail) {
  try {
    let ret = await fetch(
      "https://prod-27.canadacentral.logic.azure.com:443/workflows/c12e60fb9c0b401a97837f71abf41721/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xiNn_ZNLNn_3H-r1DTtenM5tzlhFJrFscLSds5Ei2jA",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret && ret.id) return ret.id;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function addLoadSheet(det: ILoadSheet) {
  try {
    const webHook = "https://prod-21.canadacentral.logic.azure.com:443/workflows/8f0e81bac4564f5287ce8998b713751a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=u1roCWwokHI_DhnBnqByv6ritjNg_zwbG3_UUOZlXOQ";
    let ret = await fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function updateLoadLine(det: ILoadLine) {
  try {
    const webHook = "https://prod-01.canadacentral.logic.azure.com:443/workflows/d48b31e0fa71496dbf8be7745d8482bb/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SH9XVWD00f3S5l3moPoA2lvVcRuND0n726eu8TQe87o";
    let ret = await fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function deleteLoadLine(loadSheetId: string, receiptId: string) {
  try {
    const det = {
      LoadSheetId: loadSheetId,
      ReceiptId: receiptId
    }
    const webHook = "https://prod-29.canadacentral.logic.azure.com:443/workflows/eff570a65dcd43098dea6a93e374657b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NBfgRy7QCgrqaOMdOctKOl7pJmwZrWE3p6pVIsbtsZ8";
    let ret = await fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function completeReopenLoadLine(loadSheetId: string, receiptId: string, close: boolean) {
  try {
    const det = {
      LoadSheetId: loadSheetId,
      ReceiptId: receiptId,
      Close: close
    }
    const webHook = "https://prod-04.canadacentral.logic.azure.com:443/workflows/c6a9d7a319c241f985a311d60781aad5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jkH3G40UNnfqK-pqdnfDNEorEfTQ4uRhcskKpOEJxv0";
    let ret = await fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    return ret;
  } catch (e) {
    throw e;
  }
}
export async function removeLoadLinesWait(loadSheetId: string | undefined, loadSheetNo: string | undefined, receiptIds: any | undefined) {
  try {
    const det = {
      loadSheetId: loadSheetId,
      loadSheetNo: loadSheetNo,
      receiptIds: receiptIds,
    }
    const webHook = "https://prod-04.canadacentral.logic.azure.com:443/workflows/b46e5b91b708417d905a18624776606a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Gl992nJImuyvT6GZiGQhiffAn6H4WxrJnqgVv6Nq1xM";
    let ret = fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.headers.get("Location");
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}

export async function createLoadLinesWait(loadSheetId: string | undefined, loadSheetNo: string | undefined, receiptIds: any | undefined) {
  try {
    const det = {
      loadSheetId: loadSheetId,
      loadSheetNo: loadSheetNo,
      receiptIds: receiptIds,
    }
    const webHook = "https://prod-18.canadacentral.logic.azure.com:443/workflows/02799f5d52434e1aab48766aa84dc700/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=suQDQBNfNonm8kufJwNBFxSFNuN3G6Q8nyUKhyDI_QU";
    let ret = fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.headers.get("Location");
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}

export function createLoadLinesCheck(url: string) {
  try {

    let ret = fetch(
      url,
      {
        method: "GET",
        headers: { "Content-type": "application/json" },
      }
    ).then((r) => {
      if (r.status == 200)
        return true;
      else
        return false;
      return r;
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function updateLoadSheet(det: ILoadSheet) {
  try {
    const webHook = "https://prod-18.canadacentral.logic.azure.com:443/workflows/27b7bb3197184ac4b352b391e7b8c6d2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WFvk5xoREsihovT91jSBK5h-I0cXWFv8wu5W_VttQV4";
    let ret = await fetch(
      webHook,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(det),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export async function deleteDetail(id: string) {
  try {
    let ret = await fetch(
      "https://prod-07.canadacentral.logic.azure.com:443/workflows/db32b887ace4478cad6ae19a1ebf9cbc/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OtYAqC0t9Kxjs0jI2_S9c1l6LPM9pJ2dSBM84rVacCA",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ id: id }),
      }
    ).then(async (r) => {
      return await r.json();
    });
    if (ret && ret.id) return ret.id;
    else return undefined;
  } catch (e) {
    throw e;
  }
}

export async function getDetails(receiptId: string) {
  let ret = await fetch(
    "https://prod-06.canadacentral.logic.azure.com:443/workflows/6114cd40a47b4a6d970cbc326182d488/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xjZQ-bJZLXkwaI2BpRaPuVJvPHZxpiWrF9g8nEwbx_8",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ ReceiptId: receiptId }),
    }
  ).then(async (r) => {
    return await r.json();
  });
  if (ret && ret.length > 0) return ret;
  else return undefined;
}

export async function searchReceipt(orderNo: string) {
  let ret = await fetch(
    "https://prod-07.canadacentral.logic.azure.com:443/workflows/eb5e39f28aba4f0a80896cc2b490617a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pKWsdIacLsJoutgZo0czDnKB51-N-MvHz-hPYpqqZV0",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ OrderNo: orderNo }),
    }
  ).then(async (r) => {
    return await r.json();
  });
  if (ret && ret.length > 0) return ret[0];
  else return undefined;
}

export async function listReceipts() {
  let ret = await fetch(
    "https://prod-24.canadacentral.logic.azure.com:443/workflows/c27034c96eee4c3b99baf420df91e40b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=H4W2GKrQa41-yJ1Dlx8vGN68QVsgnwlaqv9SuRybZjE",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ id: "" }),
    }
  ).then(async (r) => {
    return await r.json();
  });
  if (ret && ret.length > 0) {
    let rec: IReceipt[] = ret.map((r: any) => {
      return {
        ReceiptId: r.tf_receiptsid,
        ReceiptNo: r.tf_name,
        ShipFirstName: r.tf_shipfirstname,
        ShipLastName: r.tf_shiplastname,
        ConFirstName: r.tf_confirstname,
        ConLastName: r.tf_conlastname,
        ConCountry: r.tf_concountry,
        TotalPieces: r.tf_totalpieces,
        TotalCubic: r.tf_totalcubic,
        TotalWeight: r.tf_totalweight,
        TotalCharge: r.tf_totalcost,
      }
    });
    return rec;
  }
  else return undefined;
}

export async function searchLoadSheet(lsNo: string) {
  let ret = await fetch(
    "https://prod-16.canadacentral.logic.azure.com:443/workflows/d117e3aca924408e858d08fbe4a7cab7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2jHt5Gm2GsYUSq1P4YaUZ1a46g17F7Bz49Nd-Kr1XuQ",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ LSNo: lsNo }),
    }
  ).then(async (r) => {
    return await r.json();
  });
  console.log(ret);

  //console.log(JSON.parse(ret));

  if (ret && ret.id) return ret;
  else return undefined;
}
export async function searchLoadSheetXRM(lsNo: string) {
  let ret = await fetch(
    "https://prod-16.canadacentral.logic.azure.com:443/workflows/d117e3aca924408e858d08fbe4a7cab7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2jHt5Gm2GsYUSq1P4YaUZ1a46g17F7Bz49Nd-Kr1XuQ",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ LSNo: lsNo }),
    }
  ).then(async (r) => {
    return await r.json();
  });
  console.log(ret);

  //console.log(JSON.parse(ret));

  if (ret && ret.id) return ret;
  else return undefined;
}
export async function exportLoadSheetToWord(loadsheet: ILoadSheet) {
  let receiptIds = [];
  if (loadsheet.LoadLines) {
    for (const r of loadsheet.LoadLines) {
      receiptIds.push(r.ReceiptId);
    }
  }
  receiptIds = _.uniq(receiptIds);
  const req = {
    "LoadSheetId": loadsheet.id,
    "ReceiptIds": receiptIds,
    "MimeType": loadsheet.MimeType,
    "QRCode": loadsheet.QRCode
  }
  console.log(req);
  let ret = await fetch(
    "https://prod-22.canadacentral.logic.azure.com:443/workflows/df22ea8f5bf8422dad5df5c579621845/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lPJRACrn17aWgGHvHkgdQGKKEu1K3kTUFSXXXKkdjls",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(req),
    }
  ).then(async (r) => {
    return await r.json();
  });
  return ret;
}
export async function exportLoadSheetToExcel(loadsheet: ILoadSheet) {
  const req = {
    "LoadSheetId": loadsheet.id
  }
  console.log(req);
  let ret = await fetch(
    "https://prod-15.canadacentral.logic.azure.com:443/workflows/832876abd4b14d3b9c7538faa4634d4d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=MYmWmfSntIgMGtGD7cCjNW8qOgEok3GfCwKzJc3yd0c",
    {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(req),
    }
  ).then(async (r) => {
    return await r.json();
  });
  return ret;
}
export async function checkStatus(url: string) {
  try {
    let ret = await fetch(
      url,
      {
        method: "GET",
        headers: { "Content-type": "application/json" },
      }
    ).then(async (r) => {
      if (r.status !== 200)
        return false;
      return await r.json();
    })
    if (ret) return ret;
    else return undefined;
  } catch (e) {
    throw e;
  }
}
export function generateOrderNumber() {
  const today = new Date();
  var yyyy = today.getFullYear().toString();
  yyyy = "RC" + yyyy.slice(2);
  var MM = pad(today.getMonth() + 1, 2);
  var dd = pad(today.getDate(), 2);
  var hh = pad(today.getHours(), 2);
  var mm = pad(today.getMinutes(), 2);
  var ss = pad(today.getSeconds(), 2);

  return yyyy + "-" + MM + dd + "-" + hh + mm + ss;
}
export function generateLoadSheetNumber() {
  const today = new Date();
  var yyyy = today.getFullYear().toString();
  yyyy = "LS" + yyyy.slice(2);
  var MM = pad(today.getMonth() + 1, 2);
  var dd = pad(today.getDate(), 2);
  var hh = pad(today.getHours(), 2);
  var mm = pad(today.getMinutes(), 2);
  var ss = pad(today.getSeconds(), 2);

  return yyyy + "-" + MM + dd + "-" + hh + mm + ss;
}
function pad(number: number, length: number) {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
}
function checkString(input: string | undefined | null) {
  if (input) {
    return input.trim();
  } else return "";
}
function checkNumber(input: number | undefined | null) {
  if (input) {
    return input;
  } else return 0;
}

//SAMPLE CREATE
// export async function createTemplateRule(templateId: string, rule: IRule) {
//     var entity: any = {};
//     entity.bh_name = rule.name;
//     entity.bh_order = rule.order;
//     entity.bh_operator = rule.operator;
//     entity.bh_action = rule.action;
//     entity.bh_value = rule.value;
//     entity["bh_Question@odata.bind"] = "/bh_assessmentquestions(" + rule.question + ")";
//     entity["bh_ActionQuestion@odata.bind"] = "/bh_assessmentquestions(" + rule.actionQuestion + ")";
//     entity["bh_Template@odata.bind"] = "/bh_assessmenttemplates(" + templateId + ")";
//     entity.bh_actiongroup = rule.actionGroup;
//     const res = apiConfig.create(entity, 'bh_assessmentrules', "return=representation").then((response: any) => {
//         // console.log(JSON.stringify(response));
//         return response.bh_assessmentquestionid;
//     }).catch(function (error) {
//         throw error;
//     });
//     return res;
// }

//SAMPLE UPSERT
// export async function upsertAnswer(answer: IAnswer) {
//    var entity: any = {};
//     entity.bh_name = answer.name;
//     entity["bh_assessmentid@odata.bind"] = "/bh_assessments(" + answer.assessmentId + ")";
//     entity["bh_AssessmentTemplate@odata.bind"] = "/bh_assessmenttemplates(" + answer.assessmentTemplateId + ")";
//     entity["bh_Question@odata.bind"] = "/bh_assessmentquestions(" + answer.questionId + ")";
//     entity["bh_CompletedBy@odata.bind"] = "/systemusers(" + answer.completedById + ")";
//     entity["bh_member@odata.bind"] = "/contacts(" + answer.member + ")";
//     entity.bh_completedon = answer.completedOn;
//     entity.bh_order = answer.order;
//     entity.bh_points = answer.points;
//     entity.bh_answer = answer.text;
//     entity.bh_describe = answer.otherChoiceText;
//     entity.bh_questiontype = answer.questionType;

//     const res = await apiConfig.upsert(answer.id, 'bh_assessmentresponses', entity, "return=representation").then((response: any) => {
//         // console.log(JSON.stringify(response));
//         return response;
//     }).catch(function (error) {
//         throw error;
//     });
//     return res;
// };

//SAMPLE Call Custom API Plugin
// export async function postMCGAnswers(mcgId: string, version: string, answersJSON: string) {
//     var actionRequest = {
//         MCGID: mcgId,
//         Version: version,
//         AnswerJSON: answersJSON
//     };
//     const res = apiConfig.executeUnboundAction('bh_MCG_GetCarePlan', actionRequest).then((response: any) => {
//         // console.log(JSON.stringify(response));
//         return response;
//     }).catch(function (error) {
//         throw error;
//     });
//     return res;
// }
