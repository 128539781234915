export enum Mode {
    Customer, Pickup, LoadSheet, LoadSheetEdit, LoadSheetSearch
}
export interface IEntityParam {
    type: Mode,
    id?: string | null | undefined,
    receipt?: IReceipt,
    receiptIds?: string | string[] | null,
    detailGrid: IDetail[]
}

export interface ILoadSheet {
    id?: string,
    Name?: string,
    StatusReason: number,
    ContainerNumber?: string,
    BookingNumber?: string,
    VesselName?: string,
    Sailing?: string,
    Closes?: string,
    ETA?: string,
    LoadLines?: ILoadLine[],
    MimeType?: string,
    QRCode?: string,
}

export interface ILoadLine {
    id?: string,
    ReceiptId?: string,
    ReceiptNo?: string,
    LoadSheetId?: string,
    Name?: string,
    TSNo?: string,
    From?: string,
    To?: string,
    Description?: string,
    Weight?: number,
    TotalCubic?: number,
    SealNo?: string,
    Quantity?: number,
    QuantityLoaded?: number,
    DetailItems?: ILoadItem[],
    Status?: number,
}
export interface ILoadItem {
    id?: string,
    Description?: string,
    Weight?: number,
    TotalCubic?: number,
    SealNo?: string,
    Quantity?: number,
    QuantityLoaded?: number,
    Completed?: boolean,
}

export interface IReceipt {
    ReceiptId?: string | null,
    ReceiptNo?: string,
    ReceiptDate?: string,
    ShipFirstName?: string,
    ShipLastName?: string,
    ShipEmail?: string,
    ShipPhone?: string,
    ShipAdd1?: string,
    ShipAdd2?: string,
    ShipCity?: string,
    ShipProv?: string,
    ShipPostal?: string,
    ShipCountry?: string,

    ConFirstName?: string,
    ConLastName?: string,
    ConEmail?: string,
    ConPhone?: string,
    ConAdd1?: string,
    ConAdd2?: string,
    ConCity?: string,
    ConProv?: string,
    ConPostal?: string,
    ConCountry?: string,

    TotalPieces?: number,
    TotalCubic?: number,
    TotalWeight?: number,

    PickupDriver?: string,
    DoorToDoor?: boolean,
    Pickup?: number,
    Freight?: number,
    TotalCharge?: number,
    Collect?: number,
    Prepaid?: number,
    AdditionalInsurance?: number,
    AcceptInsurance?: boolean,
    AgreeTerms?: boolean,
    Status?: number,
    Signature?: string
    SignatureDate?: string,
    MimeType?: string,
    Type?: string,
}
export interface IDetail {
    id?: string,
    Name?: string,
    DetailId?: string,
    ReceiptId?: string,
    SealNo?: string,
    Quantity?: number,
    Length?: number,
    Width?: number,
    Height?: number,
    Cubic?: number,
    Weight?: number,
    Desc?: string
    QRCode?: string,
    MimeType?: string
}

export interface IUser {
    id: string,
    fullname: string,
    firstname: string,
    lastname: string,
    email: string,
    busunessUnitId: string,
    roles?: string[],
}


export interface IUrl {
    URL: string,
    Name: string
}

export interface IField {
    Label: string,
    Required: boolean,
    Error: boolean,
    ErrorText: string
}

export interface ISearch {
    OrderNo: string | null,
    Id: string | null
}

export interface IMessage {
    type: "error" | "warning" | "success",
    message: string
}