import {
    IEntityParam,
    ILoadLine,
    ILoadSheet,
    Mode,
} from './Interfaces';

import FetchBuilder from './FetchBuilder';
import FetchParser from './FetchParser';
import { executeFetchXML, getRoleName, getRoles } from './DataLayer';
import * as queryString from 'query-string';
import _ from 'lodash';


const fb: FetchBuilder = new FetchBuilder();
const fp: FetchParser = new FetchParser();

export function getAppMode() {
    let qs;
    if (window.location.search) {
        qs = queryString.parse(window.location.search);
    }
    else if (window.location.href) {
        qs = queryString.parse(window.location.href);
    }
    else if (window.location.href) {
        qs = queryString.parse(window.location.href);
    }
    let aType = window.location.href.toLowerCase().includes("customer") ? Mode.Customer : Mode.Pickup;
    let receiptIds: string | string[] | null = [];
    if (qs && qs.mode) {
        if (qs.mode.toString().toLowerCase().includes("loadsheetedit")) {
            aType = Mode.LoadSheetEdit;
        }
        else if (qs.mode.toString().toLowerCase().includes("loadsheetsearch")) {
            aType = Mode.LoadSheetSearch;
        }
        else if (qs.mode.toString().toLowerCase().includes("loadsheet")) {
            aType = Mode.LoadSheet;
        }

    }
    if (qs && qs.id) {
        const mode: IEntityParam = {
            type: aType,
            id: qs.id.toString(),
            detailGrid: [],
        }

        return mode;
    }
    else {
        const mode: IEntityParam = {
            type: aType,
            detailGrid: [],
            receiptIds: receiptIds
        }

        return mode;
    }
}

export async function getUser(id?: string | undefined, email?: string | undefined) {
    let fetchXml;

    if (id) {
        fetchXml = fb.GerUserById(id);
    }
    else if (email) {
        fetchXml = fb.GerUserByEmail(email);
    }
    else
        return undefined;

    let result = await executeFetchXML('systemusers', fetchXml);
    if (result && result.value.length > 0) {
        const user = fp.ParseSystemUser(result.value[0]);
        return user;
    }
    return undefined;

}

export function formatLoadLines(loadSheet: ILoadSheet) {
    let loadLines: ILoadLine[] = [];
    let receipts = _.uniqBy(loadSheet.LoadLines, 'ReceiptId');
    for (var r of receipts) {
        let loadLine: ILoadLine = {
            ReceiptId: r.ReceiptId,
            ReceiptNo: r.ReceiptNo,
            LoadSheetId: r.LoadSheetId,
            TSNo: r.TSNo,
            From: r.From,
            To: r.To,
            Status: r.Status,
            DetailItems: []
        }
        if (loadSheet.LoadLines && loadLine.DetailItems) {
            for (var l of loadSheet.LoadLines) {
                if (l.ReceiptId === r.ReceiptId) {
                    loadLine.DetailItems.push({
                        id: l.id,
                        Description: l.Description,
                        Weight: l.Weight,
                        TotalCubic: l.TotalCubic,
                        SealNo: l.SealNo,
                        Quantity: l.Quantity,
                        QuantityLoaded: l.QuantityLoaded
                    });
                }
            }
            loadLines.push(loadLine);
        }
    }
    loadLines = _.sortBy(loadLines, ['ReceiptNo']);
    return loadLines;
}

export async function getRoleNames(userId: string) {
    let roleNames: string[] = [];
    const roles = await getRoles(userId);
    if (roles) {
        for (var r of roles) {
            const name = await getRoleName(r.roleid);
            roleNames.push(name);
        }
    }
    return roleNames;

}