import { faCirclePlus, faFileExcel, faFileWord, faMinusCircle, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import QRCode from 'qrcode';

import {
  addLoadSheet,
  checkStatus,
  createLoadLinesWait,
  exportLoadSheetToExcel,
  exportLoadSheetToWord,
  generateLoadSheetNumber,
  listReceipts,
  removeLoadLinesWait,
  searchLoadSheet,
  updateLoadSheet,
} from "../common/DataLayer";
import { IEntityParam, ILoadLine, ILoadSheet, IMessage, IReceipt, Mode } from "../common/Interfaces";
import { DataGrid, GridInputSelectionModel } from '@mui/x-data-grid';
import { columnsReceipts } from "../common/Config";
import { formatLoadLines } from "../common/BusinessLogic";
import { utils, writeFileXLSX } from 'xlsx';
import LoadItemDisplay from "./LoadItemDisplay";


const LoadSheet = (props: { info: IEntityParam }) => {
  let { info } = props;
  const [loadSheet, setLoadSheet] = useState<ILoadSheet | undefined>(undefined);
  const [filteredLines, setFilteredLines] = useState<ILoadLine[]>([]);
  const [searching, setSearching] = useState(false);
  const [adding, setAdding] = useState(false);
  const [message, setMessage] = useState<IMessage | undefined>(undefined);
  const [wordUrl, setWordUrl] = useState<string | undefined>(undefined);
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingLoadSheet, setSavingLoadSheet] = useState(false);
  const [exportingWord, setExportingWord] = useState(false);
  const [exportingExcel, setExportingExcel] = useState(false);
  const [detailsAddOpen, setdetailsAddOpen] = useState(false);
  const [detailsAddLoading, setdetailsAddLoading] = useState(false);
  const [detailGrid, setDetailGrid] = useState<IReceipt[]>([]);
  const [receiptSelection, setReceiptSelection] = React.useState<any | undefined>([]);
  const [print, setPrint] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [lsNo, setLSNo] = useState("");
  const [orderNoMessage, setOrderNoMessage] = useState<IMessage | undefined>(undefined);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');

  const readOnly = info.type === Mode.LoadSheet && info.id ? true : false;

  useEffect(() => {
    if (info.id) {
      getLoadSheetNo();
    }
    else {
      // const lsNumber = generateLoadSheetNumber();
      // setLSNo(lsNumber);
      // setLoadSheet({ Name: lsNumber, StatusReason: 1 });
    }
  }, []);


  const orderNoChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLSNo(event.target.value);
    info.id = undefined;
  };

  async function createLoadSheet() {
    setSavingLoadSheet(true);

    if (loadSheet?.Name) {
      setLSNo(loadSheet?.Name);
      let dataUri = await QRCode.toDataURL("https://pickup.tennesseeinternational.ca/?mode=loadsheet&id=" + loadSheet?.Name);

      dataUri = dataUri.replace("data:", "").replace("base64", "");
      let qrCode = dataUri.split(",");
      loadSheet.QRCode = qrCode[1] ? qrCode[1] : "";
      loadSheet.MimeType = qrCode[0].replace(";", "");
      setLoadSheet(loadSheet);
      addLoadSheet(loadSheet).then((ls) => {
        if (ls) {
          loadSheet.id = ls.id;
          loadSheet.LoadLines = formatLoadLines(loadSheet);
          setLoadSheet(loadSheet);
          setSavingLoadSheet(false);
          setSearching(false);
        }
      });
    }
  }
  async function getLoadSheetNo() {
    setOrderNoMessage(undefined);
    let order = "";
    if (info.id) {
      order = info.id;
    } else if (!lsNo && lsNo.length !== 16) {
      setSearching(false);
      setOrderNoMessage({
        type: "error",
        message: "Load Sheet number must be 16 digits",
      });
      return;
    } else {
      order = lsNo;
    }
    setSearching(true);
    searchLoadSheet(order).then(async (rec) => {
      if (rec) {
        // let sht: ILoadSheet = {
        //   id: rec.id,
        //   Name: rec.Name,
        //   Status: rec.status,
        //   ContainerNumber: rec.ContainerNumber,
        //   BookingNumber: rec.BookingNumber,
        //   VesselName: rec.VesselName,
        //   Sailing: rec.Sailing,
        //   Closes: rec.Closes,
        //   MimeType: rec.MimeType,
        //   QRCode: rec.QRCode,

        // }
        rec.LoadLines = formatLoadLines(rec);
        setFilteredLines(rec.LoadLines ? rec.LoadLines : []);
        setLoadSheet(rec);
        setOrderNoMessage({ type: "success", message: "Load number found" });
        setSearching(false);
      } else {
        setOrderNoMessage({ type: "error", message: "Load Sheet not found" });
      }
      setSearching(false);
      setTimeout(() => {
        setOrderNoMessage(undefined);
      }, 3000);
    });
  }
  async function loadsheetStatus() {
    setSaving(true);
    try {
      const p = loadSheet;
      if (p) {
        p.StatusReason = loadSheet.StatusReason === 1 ? 979680001 : 1;
        await updateLoadSheet(p).then((id) => {
          if (id) {
            setSaving(false);
            setLoadSheet(p);
          }
        });
      }
    } catch (ex) {
      setSaving(false);
      setMessage({ type: "error", message: JSON.stringify(ex) });
    }

  }
  function openAddDialog() {
    setdetailsAddOpen(true);
    setdetailsAddLoading(true);
    listReceipts().then((data) => {
      if (data) {
        setDetailGrid(data);
      }
      setdetailsAddLoading(false);
    });
  }
  function closeDetails() {
    // TODO - Clear row Item variables
    setdetailsAddOpen(false);
  }
  async function addReceipt() {
    try {
      //TODO add details logic
      setdetailsAddLoading(true);
      let data: any = undefined;
      let newSelection: string[] = [];
      receiptSelection.forEach((element: string) => {
        const det = loadSheet?.LoadLines;
        if (det?.length === 0) {
          newSelection.push(element)
        }
        else if (det && det?.length > 0) {
          const add = det.find(x => x.ReceiptId === element);
          if (add === undefined)
            newSelection.push(element)
        }
      });
      if (newSelection === undefined || newSelection.length === 0) {
        setdetailsAddLoading(false);
        setdetailsAddOpen(false);
        return;
      }
      let url = await createLoadLinesWait(loadSheet?.id, loadSheet?.Name, newSelection);
      setMessage({ type: "warning", message: "Adding Receipts..." });
      for (let i = 1; i < 50; i++) {
        if (url !== undefined && url !== null && url !== "") {
          // eslint-disable-next-line no-loop-func
          await checkStatus(url).then((rec) => {
            if (rec) {
              url = undefined;
              data = rec;
              i = 1000;
            }
          });
          if (i < 50) {
            await new Promise(r => setTimeout(r, 5000));
            setMessage({ type: "warning", message: `Checking Status... ${i * 5} seconds` });
          }
        }
      }
      if (data) {
        setMessage({ type: "success", message: "Receipts Added. Load Sheet Refreshing..." });
        await searchLoadSheet(data.LoadSheetNo).then(async (rec) => {
          if (rec) {
            rec.LoadLines = formatLoadLines(rec);
            setFilteredLines(rec.LoadLines);
            setLoadSheet(rec);
            setSearchText('');
            setMessage(undefined);
            setdetailsAddLoading(false);
            setdetailsAddOpen(false);
          }
        });
      }
    } catch (ex) {
      setdetailsAddLoading(false);
      setMessage({ type: "error", message: JSON.stringify(ex) });
    }
  }
  async function deleteReceipt() {
    try {
      //TODO add details logic
      let data: any = undefined;
      let newSelection: string[] = [];
      receiptSelection.forEach((element: string) => {
        const det = loadSheet?.LoadLines;
        if (det?.length === 0) {
          newSelection.push(element)
        }
        else if (det && det?.length > 0) {
          const add = det.find(x => x.ReceiptId === element);
          if (add === undefined)
            newSelection.push(element)
        }
      });
      if (newSelection === undefined || newSelection.length === 0) {
        setdetailsAddLoading(false);
        setdetailsAddOpen(false);
        return;
      }
      let url = await createLoadLinesWait(loadSheet?.id, loadSheet?.Name, newSelection);
      setMessage({ type: "warning", message: "Adding Receipts..." });
      for (let i = 1; i < 50; i++) {
        if (url !== undefined && url !== null && url !== "") {
          // eslint-disable-next-line no-loop-func
          await checkStatus(url).then((rec) => {
            if (rec) {
              url = undefined;
              data = rec;
              i = 1000;
            }
          });
          if (i < 50) {
            await new Promise(r => setTimeout(r, 5000));
            setMessage({ type: "warning", message: `Checking Status... ${i * 5} seconds` });
          }
        }
      }
      if (data) {
        setMessage({ type: "success", message: "Receipts Added. Load Sheet Refreshing..." });
        await searchLoadSheet(data.LoadSheetNo).then(async (rec) => {
          if (rec) {
            rec.LoadLines = formatLoadLines(rec);
            setLoadSheet(rec);
            setMessage(undefined);
            setdetailsAddLoading(false);
            setdetailsAddOpen(false);
          }
        });
      }
    } catch (ex) {
      setdetailsAddLoading(false);
      setMessage({ type: "error", message: JSON.stringify(ex) });
    }
  }
  async function exportToWord() {
    setExportingWord(true);

    try {
      const p = loadSheet;
      if (p) {
        setMessage({ type: "warning", message: "Exporting to Word. Please Wait." });
        exportLoadSheetToWord(p).then((id) => {
          if (id) {
            const url = "https://3dnt.sharepoint.com/sites/TennesseeInternationalFreight" + id.Path;
            setWordUrl(url);
            setMessage(undefined);

            // var link = document.createElement("a");
            // link.download = "LoadSheet.docx";
            // link.href = url;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
          }
        });
        setExportingWord(false);
      }
    } catch (ex) {
      setMessage({ type: "error", message: JSON.stringify(ex) });
    }
  }

  function exportToExcel() {
    setExportingExcel(true);
    try {
      const p = loadSheet;
      if (p && p.LoadLines) {
        exportLoadSheetToExcel(p).then((res) => {
          if (res) {
            const data = res.data;
            const fileName = p.Name + ".xlsx"

            const fileObj = base64ToFile(data, fileName);
            downloadFile(fileObj);
            setMessage(undefined);
            setExportingExcel(false);
          }
        });
      }
    } catch (ex) {
      setMessage({ type: "error", message: JSON.stringify(ex) });
    }
  }
  function base64ToFile(dataURI: string, fileName: string) {
    const byteString = atob(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    return { url, fileName };
  }

  function downloadFile(fileObj: any) {
    const link = document.createElement('a');
    link.href = fileObj.url;
    link.download = fileObj.fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    let ls = loadSheet;
    if (ls) {
      let value = event.target.value;
      if (value) {
        value = value.toUpperCase();
      }
      if (event.target.id.includes("CNumber")) {
        ls.ContainerNumber = value;
        setLoadSheet(ls);
      }
      else if (event.target.id.includes("BNumber")) {
        ls.BookingNumber = value
        setLoadSheet(ls);
      }
      else if (event.target.id.includes("VesselName")) {
        ls.VesselName = value;
        setLoadSheet(ls);
      }
      else if (event.target.id.includes("Sailing")) {
        ls.Sailing = value;
        setLoadSheet(ls);
      }
      else if (event.target.id.includes("Closes")) {
        ls.Closes = value;
        setLoadSheet(ls);
      }
      else if (event.target.id.includes("ETA")) {
        ls.ETA = value;
        setLoadSheet(ls);
      }
      setRefresh(!refresh);
    }
  }

  function loadLineUpdate(loadline: ILoadLine) {
    setLoadSheet(loadSheet);
    setRefresh(!refresh);
  }
  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length > 1) {
      const val = event.target.value.toUpperCase();
      const lines = loadSheet?.LoadLines;
      let newLines: ILoadLine[] = [];
      lines?.forEach((l) => {
        if (l.TSNo?.toUpperCase()?.includes(val) || l.From?.includes(val) || l.To?.includes(val) || l.Description?.includes(val))
          newLines.push(l);
      });
      setFilteredLines([]);
      setTimeout(() => {
        setFilteredLines(newLines);
      }, 500);
    }
    else if (event.target.value.length === 0) {
      setFilteredLines([]);
      setTimeout(() => {
        setFilteredLines(loadSheet?.LoadLines ? loadSheet?.LoadLines : []);
      }, 500);
    }
    setSearchText(event.target.value);
  }
  function getControl(): JSX.Element {
    if (print) {
      return printLoadSheet();
    }

    if (!searching && loadSheet?.id) {
      return update()
    }
    else if (loadSheet?.id === undefined && loadSheet?.Name) {
      return update()
    }
    else if (info.type === Mode.LoadSheetSearch || searching) {
      return searchLS()
    }
    else
      return <div></div>
  }
  async function removeReceipts() {
    setShowDeleteDialog(false);
    setdetailsAddLoading(true);
    if (filteredLines.length > 0) {
      let data: any = undefined;
      let receipts: string[] = [];

      filteredLines.forEach((l) => {
        if (l.ReceiptId)
          receipts.push(l.ReceiptId);
      });
      let url = await removeLoadLinesWait(loadSheet?.id, loadSheet?.Name, receipts);
      setMessage({ type: "warning", message: "Removing Receipts..." });
      for (let i = 1; i < 50; i++) {
        if (url !== undefined && url !== null && url !== "") {
          // eslint-disable-next-line no-loop-func
          await checkStatus(url).then((rec) => {
            if (rec) {
              url = undefined;
              data = rec;
              i = 1000;
            }
          });
          if (i < 50) {
            await new Promise(r => setTimeout(r, 1000));
            setMessage({ type: "warning", message: `Checking Status...` });
          }
        }
      }
      if (data) {
        let newFilteredLines: ILoadLine[] = loadSheet?.LoadLines ? loadSheet?.LoadLines : [];
        filteredLines.forEach((l) => {
          newFilteredLines.splice(newFilteredLines.findIndex(x => x.ReceiptId === l.ReceiptId), 1);
        });
        setFilteredLines(newFilteredLines);
        setMessage({ type: "success", message: "Receipts Removed. Load Sheet Refreshing..." });
        setTimeout(() => {
          searchLoadSheet(data.LoadSheetNo).then(async (rec) => {
            if (rec) {
              rec.LoadLines = formatLoadLines(rec);
              setFilteredLines(rec.LoadLines);
              setLoadSheet(rec);
              setMessage(undefined);
              setSearchText('');
              setdetailsAddLoading(false);
              setdetailsAddOpen(false);
            }
          });
        }, 500);

      }
    }
  }

  function update() {
    return (
      <React.Fragment>
        {
          loadSheet?.id || loadSheet?.Name ?
            <div className={"MainForm" + refresh.toString()} >
              <Row xs={12} style={{ textAlign: "center", padding: 15 }}>
                <Col xs={10}>
                  <Row xs={12} style={{ padding: 10 }}>
                    <Col xs={12} md={3} style={{ textAlign: "center" }}>
                      <TextField
                        id="lsNo"
                        label="Load Sheet Number"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.Name}
                        disabled={true}
                        onChange={onTextChange}
                      />
                    </Col>
                    <Col xs={12} md={3} style={{ textAlign: "center" }}>
                      <TextField
                        id="CNumber"
                        label="Container Number"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.ContainerNumber}
                        onChange={onTextChange}
                      />
                    </Col>
                    <Col xs={12} md={3} style={{ textAlign: "center" }}>
                      <TextField
                        id="BNumber"
                        label="Booking Number"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.BookingNumber}
                        onChange={onTextChange}
                      />
                    </Col>
                    <Col xs={12} md={3} style={{ textAlign: "center" }}>
                      <TextField
                        id="VesselName"
                        label="Vessel Name"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.VesselName}
                        onChange={onTextChange}
                      />
                    </Col>
                  </Row>
                  <Row xs={12} style={{ padding: 10 }}>
                    <Col xs={12} md={4} style={{ textAlign: "center" }}>
                      <TextField
                        id="Sailing"
                        label="Sailing"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.Sailing}
                        onChange={onTextChange}
                      />
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: "center" }}>
                      <TextField
                        id="Closes"
                        label="Closes"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.Closes}
                        onChange={onTextChange}
                      />
                    </Col>
                    <Col xs={12} md={4} style={{ textAlign: "center" }}>
                      <TextField
                        id="ETA"
                        label="ETA"
                        variant="filled"
                        margin="normal"
                        fullWidth
                        value={loadSheet?.ETA}
                        onChange={onTextChange}
                      />
                    </Col>
                  </Row>
                </Col>
                {
                  loadSheet?.QRCode && props.info.type === Mode.LoadSheetEdit ?
                    <Col xs={2}>
                      <br />

                      <Paper elevation={7} style={{ paddingTop: 10 }}>
                        <img src={"data:" + loadSheet?.MimeType + ";base64," + loadSheet?.QRCode}></img>
                      </Paper>
                    </Col> :
                    <><br /><br /><br /><br /></>
                }
              </Row>
              <Row style={{ padding: 15 }}>

                {
                  loadSheet?.id ?
                    <>
                      <Col xs={12} md={2} >
                        <Box style={{ display: loadSheet.StatusReason === 1 && info.type !== Mode.LoadSheet ? "block" : "none" }} sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            onClick={createLoadSheet}
                            disabled={savingLoadSheet}
                          >
                            {"Update Info"}
                          </Button>
                          {savingLoadSheet && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            onClick={loadsheetStatus}
                            disabled={savingLoadSheet}
                          >
                            {loadSheet.StatusReason === 1 ? "Close Load Sheet" : "Reopen Load Sheet"}
                          </Button>
                          {savingLoadSheet && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Box>
                      </Col>
                      <Col xs={12} md={2} style={{ display: loadSheet.StatusReason === 1 && info.type !== Mode.LoadSheet ? "block" : "none" }} >
                        <Box sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            startIcon={
                              <FontAwesomeIcon color="#FFFFFF" icon={faCirclePlus} />
                            }
                            onClick={openAddDialog}
                          >
                            Add Receipt
                          </Button>
                        </Box>
                        <Dialog open={detailsAddOpen} maxWidth={"xl"} fullWidth fullScreen>
                          <DialogContent>
                            <div>
                              <Row md={12}>
                                {detailsAddLoading ?
                                  <div style={{ textAlign: "center" }}>
                                    <br /><br />
                                    {message ? <Alert severity={message.type} sx={{ fontSize: 20 }}>{message.message}</Alert> : ""}
                                    <CircularProgress />
                                  </div>
                                  :
                                  <Box sx={{ height: 700, width: "100%" }}>
                                    <DataGrid
                                      checkboxSelection
                                      disableColumnSelector
                                      rows={detailGrid}
                                      columns={columnsReceipts}
                                      getRowId={(row) => row.ReceiptId}
                                      pageSize={50}
                                      onSelectionModelChange={(newRowSelectionModel: GridInputSelectionModel | undefined) => {
                                        if (newRowSelectionModel)
                                          setReceiptSelection(newRowSelectionModel);
                                      }}
                                    />
                                  </Box>
                                }
                              </Row>
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeDetails}>Cancel</Button>
                            <Box sx={{ m: 1, position: "relative" }}>
                              <Button variant="text" onClick={addReceipt}>
                                Add
                              </Button>
                              {adding && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                  }}
                                />
                              )}
                            </Box>
                          </DialogActions>
                        </Dialog>
                      </Col>

                      <Col xs={12} md={2} style={{ display: loadSheet.StatusReason === 1 && info.type !== Mode.LoadSheet ? "block" : "none" }} >
                        <Box sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            disabled={exportingExcel}
                            startIcon={
                              <FontAwesomeIcon color="#FFFFFF" icon={faFileExcel} />
                            }
                            onClick={exportToExcel}
                          >
                            {"Export To Excel"}
                          </Button>
                          {exportingExcel && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Box>
                      </Col>
                      <Col xs={12} md={2} style={{ display: loadSheet.StatusReason === 1 && info.type !== Mode.LoadSheet ? "block" : "none" }} >
                        <Box sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            disabled={exportingWord}
                            startIcon={
                              <FontAwesomeIcon color="#FFFFFF" icon={faFileWord} />
                            }
                            onClick={exportToWord}
                          >
                            {"Export To Word"}
                          </Button>
                          {exportingWord && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Box>
                      </Col>

                    </>
                    :
                    <Col xs={12} md={3}  >
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          variant="contained"
                          onClick={createLoadSheet}
                          disabled={savingLoadSheet}
                        >
                          {"Create Load Sheet"}
                        </Button>
                        {savingLoadSheet && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                }

              </Row>
              <Row>
                <Col>
                  {message ? <Alert severity={message.type} sx={{ fontSize: 20 }}>{message.message}</Alert> : ""}
                  {wordUrl ? <Button type="outline" startIcon={<FontAwesomeIcon color="#FFFFFF" icon={faFileWord} />} href={wordUrl}>Click Here to Download</Button> : ""}
                  {loadSheet?.StatusReason === 979680001 ?
                    <Alert severity={"success"} sx={{ fontSize: 20 }}>
                      <AlertTitle>{"Load Sheet Complete"}</AlertTitle>
                    </Alert>
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col sm={4} style={{ paddingLeft: 35 }}>
                  <TextField
                    id={"search"}
                    fullWidth
                    label="Search Receipts"
                    value={searchText}
                    onChange={onSearchChange}
                    variant="filled"
                  />
                </Col>
                <Col sm={1} style={{ paddingLeft: 15, paddingTop: 10 }}>
                  <Chip label={"Count: " + filteredLines.length} />
                </Col>
                <Col xs={3} style={{ display: loadSheet.StatusReason === 1 && info.type !== Mode.LoadSheet ? "block" : "none" }} >
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      disabled={searchText.length === 0}
                      startIcon={
                        <FontAwesomeIcon color="#FFFFFF" icon={faMinusCircle} />
                      }
                      onClick={() => {
                        setShowDeleteDialog(true);
                      }}
                    >
                      Remove Filtered Receipts
                    </Button>
                  </Box>
                  <Dialog open={showDeleteDialog} >
                    <DialogContent>
                      <div>
                        <Alert severity={"warning"} sx={{ fontSize: 16 }}>Are you sure you would like to remove these items from the Load Sheet?</Alert>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowDeleteDialog(false)}>No</Button>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button variant="text" onClick={removeReceipts}>
                          Yes
                        </Button>
                        {deleting && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </DialogActions>
                  </Dialog>
                </Col>
              </Row>
              <br />
              {
                !detailsAddLoading ?
                  <Col>
                    {
                      filteredLines.map((l, idx) => {
                        return <Row xs={12} style={{ padding: 20, backgroundColor: idx % 2 ? "#EFEFEF" : "#FFFFFF" }}>
                          <LoadItemDisplay key={l.id} info={l} readOnly={readOnly} callBack={loadLineUpdate} />
                        </Row>
                      })}
                  </Col>
                  : ""
              }

              <Row xs={12}>
                <Col>
                  <hr />
                </Col>
              </Row>
              <br />
              <br />
              <br />
            </div> : ""
        }
      </React.Fragment >
    );

  }
  function searchLS() {
    return (
      <React.Fragment>
        <div className="mainForm">
          <Row xs={12} style={{ textAlign: "center", padding: 15 }}>
            <Col xs={12}>
              <Paper elevation={7}>
                <Typography fontSize={18} fontWeight={10}>
                  Tennessee International Freight <br />
                  Load Sheet Search
                </Typography>
              </Paper>
            </Col>
          </Row>
          <br />
          <Row xs={12} style={{ padding: 10 }}>
            <Col xs={12}
              style={{
                display: searching ? "block" : "none",
                textAlign: "center",
              }}
            >
              <LinearProgress />
            </Col>
            <Col xs={12} style={{ textAlign: "center", display: loadSheet?.id !== undefined || searching ? "none" : "block" }}>
              <TextField
                id="orderNo"
                label="Existing Load Sheet Number"
                variant="filled"
                margin="normal"
                fullWidth
                value={lsNo}
                onChange={orderNoChanged}
              />
            </Col>
            <Col xs={12} style={{ textAlign: "center", display: loadSheet?.id !== undefined || searching ? "none" : "block" }}>
              <IconButton aria-label="search">
                <FontAwesomeIcon
                  color="#d12124"
                  icon={faSearch}
                  size={"6x"}
                  onClick={() => {
                    getLoadSheetNo();
                  }}
                />
              </IconButton>
            </Col>
          </Row>
          <Row sm={12} style={{ textAlign: "center", padding: 50, display: loadSheet?.id !== undefined || searching ? "none" : "block" }}>
            <Col style={{ textAlign: "center" }}>
              <IconButton aria-label="create">
                <FontAwesomeIcon
                  color="#d12124"
                  icon={faCirclePlus}
                  size={"8x"}
                  onClick={() => {
                    let oNumber = generateLoadSheetNumber();
                    setLSNo(oNumber);
                    setLoadSheet({ Name: oNumber, StatusReason: 1 })
                  }}
                />
              </IconButton>
            </Col>
          </Row>
          <Row
            className="justify-content-center"
            sm={12}
            style={{ paddingTop: 10 }}
          >
            <Col xs={1} md={1}></Col>

            <Col
              xs={12}
              md={8}
              style={{
                display: orderNoMessage ? "block" : "none",
                textAlign: "center",
              }}
            >
              <Alert severity={orderNoMessage?.type} sx={{ fontSize: 20 }}>
                <AlertTitle>{orderNoMessage?.message}</AlertTitle>
              </Alert>
            </Col>

            <Col xs={1} md={1}></Col>
          </Row>
          <Row sm={12} style={{ padding: 50, display: searching ? "block" : "none" }}>
            <Col style={{ textAlign: "center" }}>
              <Alert severity={"info"} sx={{ fontSize: 20 }}>
                <AlertTitle>Opening Load Sheet</AlertTitle>
              </Alert>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  function printLoadSheet() {
    return <>
      <Container fluid={true}>
        <Row style={{ backgroundColor: "yellow" }}>
          <Col xs={10}>
            <Row>
              <Col xs={9}>
                LIST OF FREIGHT IN  CONTAINER: <b>{loadSheet?.ContainerNumber}</b>
              </Col>
              <Col xs={3}>
                ETA: {loadSheet?.ETA}
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                BOOKING NO: {loadSheet?.BookingNumber}
              </Col>
              <Col xs={3}>
                VESSEL: {loadSheet?.VesselName}
              </Col>
              <Col xs={3}>
                CLOSES: {loadSheet?.Closes}
              </Col>
              <Col xs={3}>
                SAILING: {loadSheet?.Sailing}
              </Col>
            </Row>
          </Col>
          <Col xs={2} style={{ paddingBottom: 2 }}>
            <img height={90} src={"data:" + loadSheet?.MimeType + ";base64," + loadSheet?.QRCode}></img>
            <br />
          </Col>
        </Row>
        <Row style={{ border: "1px solid black", fontSize: 9 }}>
          <Col xs={1} style={{ borderRight: "1px solid black" }}>TS NO</Col>
          <Col xs={2} style={{ borderRight: "1px solid black" }}>From</Col>
          <Col xs={3} style={{ borderRight: "1px solid black" }}>To</Col>
          <Col xs={3} style={{ borderRight: "1px solid black" }}>AMOUNT & DESCRIPTION</Col>
          <Col xs={1} style={{ borderRight: "1px solid black" }}>WT (KG)</Col>
          <Col xs={1} style={{ borderRight: "1px solid black" }}>DIMS</Col>
          <Col xs={1} style={{ borderRight: "1px solid black" }}>Loaded</Col>
        </Row>
        {
          loadSheet?.LoadLines?.map((l, idx) => {
            return <Row style={{ whiteSpace: "pre-line", borderBottom: "1px solid black", borderLeft: "1px solid black", fontSize: 10 }}>

              <Col xs={1} style={{ borderRight: "1px solid black" }}>{l.TSNo}</Col>
              <Col xs={2} style={{ borderRight: "1px solid black" }}>{l.From}</Col>
              <Col xs={3} style={{ borderRight: "1px solid black" }}>{l.To}</Col>
              <Col xs={6}>
                {l.DetailItems?.map((i, idx) => {
                  return <>
                    <Row style={{ borderTop: "1px dotted black", borderRight: "1px dotted black", borderBottom: "1px dotted black", }}>
                      <Col xs={6} style={{ borderRight: "1px solid black" }}>{i.Quantity} {i.Description} {i.SealNo}</Col>
                      <Col xs={2} style={{ borderRight: "1px solid black", paddingLeft: 30 }}>{i.Weight}</Col>
                      <Col xs={2} style={{ borderRight: "1px solid black", paddingLeft: 30 }}>{i.TotalCubic}</Col>
                      <Col xs={2} style={{ borderRight: "1px solid black", paddingLeft: 30 }}>{i.QuantityLoaded}</Col>
                    </Row>
                  </>
                })
                }
              </Col>
            </Row>
          })
        }
      </Container>

    </>
  }

  return getControl();
}
export default LoadSheet;

