import { faAddressCard, faBoxesStacked, faUserClock, faFileSignature } from "@fortawesome/free-solid-svg-icons"
import { faAddressCard as fac } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"

export const icons = (index: number) => {
    switch (index) {
        case 0:
            return <FontAwesomeIcon color='#d12124' icon={faAddressCard} size={"2x"} />
        case 1:
            return <FontAwesomeIcon color='#d12124' icon={fac} size={"2x"} />
        case 2:
            return <FontAwesomeIcon color='#d12124' icon={faBoxesStacked} size={"2x"} />
        case 3:
            return <FontAwesomeIcon color='#d12124' icon={faUserClock} size={"2x"} />
        case 4:
            return <FontAwesomeIcon color='#d12124' icon={faFileSignature} size={"2x"} />
    }
}
export const columnsDetails: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'SealNo',
        headerName: 'Seal No',
        width: 150,
        editable: true,
    },
    {
        field: 'Quantity',
        headerName: 'Quantity',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'Weight',
        headerName: 'Weight',
        type: 'number',
        width: 100,
        editable: true,
    },
    {
        field: 'Desc',
        headerName: 'Description of packages and goods',
        width: 520,
        editable: true,
    },

];

export const columnsLoadSheet: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
        field: 'SealNo',
        headerName: 'Seal No',
        width: 150,
        editable: true,
    },
    {
        field: 'Quantity',
        headerName: 'Quantity',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'Weight',
        headerName: 'Weight',
        type: 'number',
        width: 100,
        editable: true,
    },
    {
        field: 'Desc',
        headerName: 'Description of packages and goods',
        width: 520,
        editable: true,
    },

];

export const columnsReceipts: GridColDef[] = [
    { field: 'ReceiptId', headerName: 'ID', width: 90, hide: true },
    {
        field: 'ReceiptNo',
        headerName: 'Receipt No',
        width: 150,
        editable: true,
    },
    {
        field: 'ShipFirstName',
        headerName: 'ShipFirstName',
        width: 130,
        editable: true,
    },
    {
        field: 'ShipLastName',
        headerName: 'ShipLastName',
        width: 130,
        editable: true,
    },
    {
        field: 'ConFirstName',
        headerName: 'ConFirstName',
        width: 130,
        editable: true,
    },
    {
        field: 'ConLastName',
        headerName: 'ConLastName',
        width: 130,
        editable: true,
    },

    {
        field: 'ConCountry',
        headerName: 'ConCountry',
        width: 130,
        editable: true,
    },
    {
        field: 'TotalPieces',
        headerName: 'TotalPieces',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'TotalCubic',
        headerName: 'TotalCubic',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'TotalWeight',
        headerName: 'TotalWeight',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'TotalCharge',
        headerName: 'TotalCharge',
        type: 'number',
        width: 110,
        editable: true,
    }
];
// export const rows: GridRowsProp = [{
//     "id": 1,
//     "marks": "Onfre",
//     "sealno": "6761521926782757",
//     "quantity": 84,
//     "weight": 60.0,
//     "desc": "Suspendisse potenti."
// }, {
//     "id": 2,
//     "marks": "Rand",
//     "sealno": "4405178377832420",
//     "quantity": 19,
//     "weight": 32.2,
//     "desc": "Donec quis orci eget orci vehicula condimentum."
// }, {
//     "id": 3,
//     "marks": "Rhonda",
//     "sealno": "5602242064384869",
//     "quantity": 1,
//     "weight": 91.1,
//     "desc": "Ut tellus."
// }, {
//     "id": 4,
//     "marks": "Shirley",
//     "sealno": "3560696286130597",
//     "quantity": 78,
//     "weight": 92.4,
//     "desc": "Duis bibendum. Morbi non quam nec dui luctus rutrum."
// }, {
//     "id": 5,
//     "marks": "Kaia",
//     "sealno": "3584782265173014",
//     "quantity": 45,
//     "weight": 69.5,
//     "desc": "Integer tincidunt ante vel ipsum."
// }, {
//     "id": 6,
//     "marks": "Dougy",
//     "sealno": "30323142895259",
//     "quantity": 12,
//     "weight": 33.4,
//     "desc": "Nunc nisl. Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa."
// }, {
//     "id": 7,
//     "marks": "Pierette",
//     "sealno": "3586262614904003",
//     "quantity": 10,
//     "weight": 62.3,
//     "desc": "Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam."
// }, {
//     "id": 8,
//     "marks": "Moshe",
//     "sealno": "30511068186980",
//     "quantity": 38,
//     "weight": 14.5,
//     "desc": "Nam nulla. Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede."
// }, {
//     "id": 9,
//     "marks": "Halley",
//     "sealno": "67717595875997902",
//     "quantity": 57,
//     "weight": 70.5,
//     "desc": "Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis."
// }, {
//     "id": 10,
//     "marks": "Dulci",
//     "sealno": "4405408748369335",
//     "quantity": 45,
//     "weight": 84.7,
//     "desc": "Nullam varius. Nulla facilisi."
// }]
