import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faCoffee,
  faAddressCard,
  faBoxesStacked,
  faCheckCircle,
  faPallet,
  faTruckLoading,
  faCirclePlus,
  faEdit,
  faTrash,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import SignatureCanvas from "react-signature-canvas";
import QRCode from 'qrcode';

import { deleteDetail, upsertDetail, upsertReceipt } from "../common/DataLayer";
import {
  IDetail,
  IEntityParam,
  IMessage,
  IReceipt,
  Mode,
} from "../common/Interfaces";
import { columnsDetails, icons } from "../common/Config";
library.add(
  faCheckSquare,
  faCoffee,
  faAddressCard,
  faBoxesStacked,
  faCheckCircle,
  faPallet,
  faTruckLoading
);

const Receipt = (props: { info: IEntityParam }) => {
  const { info } = props;
  const [receipt, setReceipt] = useState<IReceipt | undefined>(info.receipt);
  const [detail, setDetail] = useState<IDetail | undefined>(undefined);
  const [detailGrid, setDetailGrid] = useState<IDetail[]>(info.detailGrid);

  const [activeStep, setActiveStep] = useState(0);

  const [detailsOpen, setdetailsOpen] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [complete, setComplete] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);

  const [termsOpen, setTermsOpen] = useState(false);
  const [receiptViewOpen, setReceiptView] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [message, setMessage] = useState<IMessage | undefined>(undefined);

  const sigCanvas = React.createRef<SignatureCanvas>();

  const steps = [
    "Shipper's Info",
    "Consignee's Info",
    "Shipment Details",
    "Reviewing",
    "Sign and Complete",
  ];
  let isMobile = true;
  let buttonFont = isMobile ? 15 : 25;
  let screenWidth = window.innerWidth / 1.2;

  useEffect(() => {
    if (sigCanvas.current) {
      sigCanvas.current?.fromDataURL("data:image/png;base64," + receipt?.Signature);
    }
  }, [activeStep]);

  //#region Format correction Functions
  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "").padEnd(10, "0");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  function formatPostalCode(postalCode: string) {
    var regex = new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$");
    var match = regex.exec(postalCode);
    if (match) {
      if (
        (postalCode.indexOf("-") !== -1 || postalCode.indexOf(" ") !== -1) &&
        postalCode.length === 7
      ) {
        return postalCode;
      } else if (
        (postalCode.indexOf("-") === -1 || postalCode.indexOf(" ") === -1) &&
        postalCode.length === 6
      ) {
        return postalCode;
      }
    } else {
      return "";
    }
  }
  function formatEmail(email: string) {
    const valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (valid) return email;
    else return "";
  }
  //#endregion

  //#region Validation Functions
  function validateShipping() {
    let valid = true;

    if (!receipt?.ShipFirstName || receipt?.ShipFirstName.trim() === "") {
      setErrorMessage("First Name Is Required");
      return false;
      // valid = false;
    }
    if (!receipt?.ShipEmail || receipt?.ShipEmail.trim() === "") {
      setErrorMessage("E-Mail Is Required");
      return false;
      // valid = false;
    } else {
      const email = formatEmail(receipt?.ShipEmail.trim());
      if (email === "") {
        setErrorMessage("E-Mail Is Not Valid");
        return false;
      } else {
        setReceipt({
          ...receipt,
          "ShipEmail": email,
        });
      }
    }

    if (!receipt?.ShipLastName || receipt?.ShipLastName.trim() === "") {
      setErrorMessage("Last Name Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ShipAdd1 || receipt?.ShipAdd1.trim() === "") {
      setErrorMessage("Address Line 1 Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ShipCity || receipt?.ShipCity.trim() === "") {
      setErrorMessage("City Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ShipProv || receipt?.ShipProv.trim() === "") {
      setErrorMessage("Province Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ShipPhone || receipt?.ShipPhone.trim() === "") {
      setErrorMessage("Phone Number Is Required");
      return false;
      // valid = false;
    } else {
      const phone = formatPhoneNumber(receipt?.ShipPhone.trim());
      if (phone === "") {
        setErrorMessage("Phone Number Is Not Valid");
        return false;
      } else {
        setReceipt({
          ...receipt,
          "ShipPhone": phone,
        });
      }
    }
    if (!receipt?.ShipPostal || receipt?.ShipPostal.trim() === "") {
      setErrorMessage("Postal Code Is Required");
      return false;
      // valid = false;
    } else {
      const postal = formatPostalCode(receipt?.ShipPostal.trim());
      if (postal === "") {
        setErrorMessage("Postal Code Is Not Valid");
        return false;
      } else {
        setReceipt({
          ...receipt,
          "ShipPostal": postal,
        });
      }
    }

    // setMessage(undefined)
    return valid;
  }

  function validateConsignee() {
    let valid = true;
    if (!receipt?.ConFirstName || receipt?.ConFirstName.trim() === "") {
      setErrorMessage("First Name Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ConLastName || receipt?.ConLastName.trim() === "") {
      setErrorMessage("Last Name Is Required");
      return false;
      // valid = false;
    }

    if (!receipt?.ConAdd1 || receipt?.ConAdd1.trim() === "") {
      setErrorMessage("Address Line 1 Is Required");
      return false;
      // valid = false;
    }
    if (!receipt?.ConCountry || receipt?.ConCountry.trim() === "") {
      setErrorMessage("Country Is Required");
      return false;
      // valid = false;
    }

    setMessage(undefined);
    return valid;
  }

  function validateDetails() {
    let valid = true;
    let totalPieces = 0;
    let totalWeight = 0;
    let totalCubic = 0;
    if (!detailGrid || detailGrid.length === 0) {
      setErrorMessage("At least one item is required");
      return false;
    }
    for (const i of detailGrid) {
      if (i.Quantity && i.Quantity >= 0) totalPieces += i.Quantity;
      if (i.Weight && i.Weight >= 0) totalWeight += i.Weight;
      if (i.Cubic && i.Cubic >= 0) totalCubic += i.Cubic;
      const rec = receipt;
      if (rec) {
        rec.TotalPieces = totalPieces;
        rec.TotalWeight = totalWeight;
        rec.TotalCubic = totalCubic;
        setReceipt(rec);
      }
    }
    return valid;
  }

  function validateReview() {
    let valid = true;
    if (!receipt?.PickupDriver || receipt?.PickupDriver.trim() === "") {
      setErrorMessage("Pickup Driver / Receiving Clerk");
      return false;
      // valid = false;
    }

    return valid;
  }

  function validateSign() {
    let valid = true;

    return valid;
  }

  function setErrorMessage(message: string) {
    setMessage({ type: "error", message: message });
  }
  //#endregion

  //#region OnChange Functions
  function onDetailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDetail({
      ...detail,
      [event.target.id]: event.target.value
        ? event.target.value.toUpperCase()
        : "",
    });
  }
  function onNumberChangeDetail(event: React.ChangeEvent<HTMLInputElement>) {
    setDetail({
      ...detail,
      [event.target.id]: event.target.value
        ? parseInt(event.target.value)
        : null,
    });
  }
  function onNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setReceipt({
      ...receipt,
      [event.target.id]: event.target.value
        ? parseInt(event.target.value)
        : null,
    });
  }
  function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.id !== "PickupDriver") {
      setReceipt({
        ...receipt,
        [event.target.id]: event.target.value
          ? event.target.value.toUpperCase()
          : "",
      });
    } else {
      setReceipt({
        ...receipt,
        [event.target.id]: event.target.value ? event.target.value : "",
      });
    }
    setTimeout(() => {
      setMessage(undefined);
    }, 600);
  }
  function onFieldChangeCust(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.id === "Quantity") {
      setDetail({
        ...detail,
        [event.target.id]: event.target.value ? parseInt(event.target.value) : 0,
      });
      setReceipt({
        ...receipt,
        "TotalPieces": parseInt(event.target.value)
      });
    }
    else if (event.target.id === "Desc") {
      setDetail({
        ...detail,
        [event.target.id]: event.target.value ? event.target.value.toUpperCase() : "",
      });
    }
  }
  function onCheckBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setReceipt({
      ...receipt,
      [event.target.id]: event.target.checked === true ? true : false,
    });
    setTimeout(() => {
      setMessage(undefined);
    }, 600);
  }
  function onCountryChange(event: SelectChangeEvent) {
    setReceipt({
      ...receipt,
      "ConCountry": event.target.value
    });
  };
  //#endregion

  //#region Detail Functions
  function closeDetails() {
    // TODO - Clear row Item variables
    setdetailsOpen(false);
  }
  async function addDetail() {
    //TODO add details logic
    if (detail && detail.Name) {
      let dataUri = await QRCode.toDataURL("https://pickup.tennesseeinternational.ca/?ReceiptLine=" + detail.Name);
      dataUri = dataUri.replace("data:", "").replace("base64", "");
      let qrCode = dataUri.split(",");
      detail.QRCode = qrCode[1] ? qrCode[1] : "";
      detail.MimeType = qrCode[0].replace(";", "");
      setAdding(true);
      console.log(JSON.stringify(detail));
      try {
        await upsertDetail(detail).then((id) => {
          if (id) {
            detail.id = id;
            let d = detailGrid.slice();
            const idx = d.findIndex((o) => o.id === id);
            if (idx > -1) {
              d.splice(idx, 1); // 2nd parameter means remove one item only
            }
            d.push(detail);
            setDetailGrid(d);
            setdetailsOpen(false);
            setAdding(false);
          }
        });
      } catch (ex) {
        setAdding(false);
        setMessage({ type: "error", message: JSON.stringify(ex) });
      }
    }
  }
  async function deleteDetailItem() {
    if (detail && detail.id) {
      setDeleting(true);
      try {
        await deleteDetail(detail.id).then((id) => {
          if (id) {
            let d = detailGrid.slice();
            const idx = d.findIndex((o) => o.id === id);
            if (idx > -1) {
              d.splice(idx, 1); // 2nd parameter means remove one item only
            }
            setDetailGrid(d);
            setDeleteDialogOpen(false);
            setDeleting(false);
            setDetail(undefined);
          }
        });
      } catch (ex) {
        setDeleting(false);
        setMessage({ type: "error", message: JSON.stringify(ex) });
      }
    }
  }
  function openAddDialog() {
    // TODO - Clear row Item variables
    if (receipt?.ReceiptId) {
      var num = detailGrid?.length ? detailGrid.length + 1 : 1;
      const add: IDetail = {
        id: "",
        ReceiptId: receipt?.ReceiptId,

        Name: receipt?.ReceiptNo + "-" + num
      };
      setDetail(add);
      setdetailsOpen(true);
    }
  }
  function openEditDialog() {
    // TODO - Clear row Item variables
    if (receipt?.ReceiptId) {
      //setDetail(detai);
      setdetailsOpen(true);
    }
  }
  const selectClick = (newSelectionModel: any) => {
    const det = detailGrid.find((o) => o.id === newSelectionModel[0]);
    setDetail(det);
  };
  //#endregion

  //#region Main Stepper Components
  function Shippers() {
    return (
      <div>
        <Row sm={12}>
          {/* <Col xs={12} sm={6} md={3}>
                        <TextField id="OrderNo" label="Receipt Number" variant="filled" margin="normal" fullWidth disabled value={receipt?.ReceiptNo} />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <TextField id="Date" label="Date" variant="filled" margin="normal" fullWidth disabled value={receipt?.ReceiptDate} />
                    </Col> */}
          <Col xs={12} sm={6} md={6}>
            <TextField
              id="ShipEmail"
              label="Email"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipEmail}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <TextField
              id="ShipPhone"
              label="Phone Number"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipPhone}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col xs={12} sm={6}>
            <TextField
              id="ShipFirstName"
              label="First Name"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipFirstName}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6}>
            <TextField
              id="ShipLastName"
              label="Last Name"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipLastName}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col xs={12}>
            <TextField
              id="ShipAdd1"
              label="Address Line 1"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipAdd1}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row xs={12}>
          <Col xs={12}>
            <TextField
              id="ShipAdd2"
              label="Address Line 2"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              value={receipt?.ShipAdd2}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row xs={12}>
          <Col xs={12} sm={6} md={4}>
            <TextField
              id="ShipCity"
              label="City"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipCity}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <TextField
              id="ShipProv"
              label="Province"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipProv}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <TextField
              id="ShipPostal"
              label="Postal Code"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipPostal}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <TextField
              id="ShipCountry"
              label="Country"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ShipCountry}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          sm={12}
          style={{ paddingTop: 20, paddingBottom: 0 }}
        >
          <Col xs={12} sm={8}>
            <Alert severity="error" sx={{ fontSize: 20 }}>
              <AlertTitle>
                <strong>WARNING</strong>
              </AlertTitle>
              Enter Name Correctly <br /><strong>$40 for name changes </strong>
              <br />
              All Cargo Shipped at Owners Risk
            </Alert>
          </Col>
        </Row>
      </div>
    );
  }
  function Consignee() {
    return (
      <div>
        <Row sm={12}>
          <Col sm={12} md={6}>
            <TextField
              id="ConEmail"
              autoComplete="ConEmail"
              label="Email"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              value={receipt?.ConEmail}
              onChange={onFieldChange}
            />
          </Col>
          <Col sm={12} md={6}>
            <TextField
              id="ConPhone"
              autoComplete="ConPhone"
              label="Phone"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              value={receipt?.ConPhone}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={12} md={6}>
            <TextField
              id="ConFirstName"
              autoComplete="ConFirstName"
              label="First Name"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ConFirstName}
              onChange={onFieldChange}
            />
          </Col>
          <Col sm={12} md={6}>
            <TextField
              id="ConLastName"
              autoComplete="ConLastName"
              label="Last Name"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ConLastName}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={12}>
            <TextField
              id="ConAdd1"
              autoComplete="ConAdd1"
              label="Address Line 1"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              required
              value={receipt?.ConAdd1}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={12}>
            <TextField
              id="ConAdd2"
              autoComplete="ConAdd2"
              label="Address Line 2"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              value={receipt?.ConAdd2}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={12} md={6}>
            <TextField
              id="ConCity"
              autoComplete="ConCity"
              label="City"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              value={receipt?.ConCity}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <FormControl size="small" fullWidth margin="normal">
              <InputLabel id="ConCountry-label">Country</InputLabel>
              <Select
                labelId="ConCountry-label"
                id="ConCountry"
                label="Country"
                variant="outlined"
                fullWidth
                required
                defaultValue={"GUYANA"}
                value={receipt?.ConCountry}
                onChange={onCountryChange}
              >
                <MenuItem value={"ANTIGUA"}>ANTIGUA</MenuItem>
                <MenuItem value={"BARBADOS"}>BARBADOS</MenuItem>
                <MenuItem value={"GUYANA"}>GUYANA</MenuItem>
                <MenuItem value={"JAMAICA - KINGSTON"}>JAMAICA - KINGSTON</MenuItem>
                <MenuItem value={"JAMAICA - MONTEGO BAY"}>JAMAICA - MONTEGO BAY</MenuItem>
                <MenuItem value={"ST. LUCIA"}>ST. LUCIA</MenuItem>
                <MenuItem value={"ST. VINCENT"}>ST. VINCENT</MenuItem>
                <MenuItem value={"TRINIDAD AND TOBAGO"}>TRINIDAD AND TOBAGO</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }
  function Details() {
    return <>
      <Dialog open={detailsOpen} maxWidth={"xl"} fullWidth>
        <DialogContent>
          <div>
            <Row md={12}>
              <Col md={6}>
                <TextField
                  id="SealNo"
                  label="Seal No"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.SealNo}
                  onChange={onDetailChange}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="Quantity"
                  type={"number"}
                  label="Quantity"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.Quantity}
                  onChange={onNumberChangeDetail}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={2}>
                <TextField
                  id="Length"
                  type={"number"}
                  label="Length"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.Length}
                  onChange={onNumberChangeDetail}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="Width"
                  type={"number"}
                  label="Width"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.Width}
                  onChange={onNumberChangeDetail}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="Height"
                  type={"number"}
                  label="Height"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.Height}
                  onChange={onNumberChangeDetail}
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="Cubic"
                  type={"number"}
                  label="Cubic"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  value={detail?.Cubic}
                  onChange={onNumberChangeDetail}
                />
              </Col>
              <Col md={2}>
                <TextField
                  id="Weight"
                  label="Weight"
                  margin="normal"
                  size="small"
                  type={"number"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">kg</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={onNumberChangeDetail}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  id="Desc"
                  label="Description of packages and goods"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  multiline
                  rows={5}
                  value={detail?.Desc}
                  onChange={onDetailChange}
                />
              </Col>
            </Row>
            <Row
              className="justify-content-center"
              sm={12}
              style={{ paddingTop: 20 }}
            >
              <Col md={8}>
                <Alert severity="error" sx={{ fontSize: 20 }}>
                  <AlertTitle>
                    <strong>Due to SOLAS</strong>
                  </AlertTitle>
                  Maximum barrel weight: <strong>250 lbs</strong>
                  <br />
                  Maximum box weight under 5 cuft: <strong>50 lbs</strong>
                  <br />
                  <strong>
                    Any cargo over these limits are subject to surcharge
                  </strong>
                </Alert>
              </Col>
            </Row>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetails}>Cancel</Button>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button variant="text" onClick={addDetail} disabled={deleting}>
              {detail && detail.id ? "Update" : "Add"}
            </Button>
            {adding && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} maxWidth={"lg"}>
        <DialogContent>
          <div style={{ minWidth: "300px" }}>
            <Typography>
              Are you sure you want to delete this line?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
            }}
          >
            No
          </Button>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="text"
              onClick={() => {
                deleteDetailItem();
              }}
              disabled={deleting}
            >
              Yes
            </Button>
            {deleting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <>
        <Row>
          <Col xs={4}>
            <Button
              variant="contained"
              startIcon={
                <FontAwesomeIcon color="#FFFFFF" icon={faCirclePlus} />
              }
              onClick={openAddDialog}
            >
              Add
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              disabled={detail && detail.id ? false : true}
              variant="contained"
              startIcon={<FontAwesomeIcon color="#FFFFFF" icon={faEdit} />}
              onClick={openEditDialog}
            >
              Edit
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              disabled={detail && detail.id ? false : true}
              variant="contained"
              startIcon={<FontAwesomeIcon color="#FFFFFF" icon={faTrash} />}
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row >
        <Row>
          <Col>
            <Box sx={{ height: 360, width: "100%" }}>
              <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                getRowId={(row) => row.id}
                rows={detailGrid}
                columns={columnsDetails}
                pageSize={5}
                onSelectionModelChange={selectClick}
              />
            </Box>
          </Col>
        </Row>
      </>
    </>
  }
  function DetailsCustomer() {
    return <>
      <span style={{ fontSize: 16, fontWeight: 500, paddingTop: 20 }}>Please sign below and agree to the terms and conditions before completing this receipt</span>

      <Row>
        <Col xs={3} md={3}>
          <TextField
            id="Quantity"
            label="Qty"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={detail?.Quantity}
            onChange={onFieldChangeCust}
          />
        </Col>
        <Col xs={12} md={9}>
          <TextField
            id="Desc"
            label="Description"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={detail?.Desc}
            onChange={onFieldChangeCust}
          />
        </Col>
      </Row>

      <div style={{ paddingTop: 20 }}>
        <Row
          sm={12}
          style={{
            paddingTop: 12,
            zIndex: 2000,
            display: saving ? "none" : "block",
          }}
        >
          <Col style={{ textAlign: "center" }}>
            <Button
              sx={{ height: 30, fontSize: 18 }}
              variant="text"
              onClick={clearSig}
            >
              Clear Signature
            </Button>
            <br />
            {receipt?.Signature ?
              <img alt="" src={"data:image/png;base64," + receipt?.Signature} /> :

              <SignatureCanvas
                backgroundColor="rgba(235, 235, 235, 1)"
                ref={sigCanvas}
                penColor="darkblue"
                canvasProps={{ height: 300, width: screenWidth - 10 }}
              />
            }
          </Col>
        </Row>
        <br />
        <Row xs={12}>
          <Col></Col>
          <Col xs={12} sm={6} style={{ textAlign: "center" }}>
            {Terms()}
            <Button
              sx={{ height: 33, fontSize: 15 }}
              variant="contained"
              onClick={() => {
                setTermsOpen(true);
              }}
            >
              View Terms and Conditions
            </Button>
          </Col>
          <Col></Col>
        </Row>
        <Row xs={12}>
          <Col></Col>
          <Col xs={11} style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
                  checked={agreeTerms}
                  onChange={() => {
                    //sigCanvas.current?.fromDataURL("data:image/png;base64," + receipt?.Signature);
                    setAgreeTerms(!agreeTerms);
                  }}
                />
              }
              label="I agree to the terms and conditions"
            />
          </Col>
          <Col></Col>
        </Row>
      </div>
    </>
  }
  function Review() {
    return (
      <>
        <Row>
          <Col xs={6} md={4} style={{ textAlign: "left" }}>
            <TextField
              id="PickupDriver"
              label="Pickup Driver / Receiving Clerk"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              value={receipt?.PickupDriver}
              onChange={onFieldChange}
            />
          </Col>
          <Col xs={6} md={4} style={{ textAlign: "left" }}>
            <FormControlLabel
              sx={{ paddingTop: 1 }}
              control={
                <Checkbox
                  id="DoorToDoor"
                  onChange={onCheckBoxChange}
                  checked={receipt?.DoorToDoor}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />
              }
              label="Door To Gate"
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col xs={6} md={3}>
            <TextField
              id="Pickup"
              label="Pickup Charge"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.Pickup}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <TextField
              id="Freight"
              label="Freight Charge"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.Freight}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <TextField
              id="TotalCharge"
              label="Total Charge"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.TotalCharge}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <TextField
              id="Prepaid"
              label="Prepaid"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.Prepaid}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <TextField
              id="AdditionalInsurance"
              label="Additional Insurance"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.AdditionalInsurance}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
          <Col xs={6} md={3}>
            <TextField
              id="Collect"
              label="Collect"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.Collect}
              onChange={onNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row sm={12}>
          <Col xs={4} md={4}>
            <TextField
              id="TotalPieces"
              label="Total Pieces"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.TotalPieces}
              onChange={onNumberChange}
            />
          </Col>
          <Col xs={4} md={4}>
            <TextField
              id="TotalCubic"
              label="Total Cubic"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.TotalCubic}
              onChange={onNumberChange}
            />
          </Col>
          <Col xs={4} md={4}>
            <TextField
              id="TotalWeight"
              label="Total Weight"
              variant="outlined"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              value={receipt?.TotalWeight}
              onChange={onNumberChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">lbs</InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
  function clearSig() {
    sigCanvas.current?.clear();
    setReceipt({ ...receipt, Signature: "" });
  }
  function Sign() {
    return (
      <>
        <div style={{ paddingTop: 20 }}>
          <Row xs={12}>
            <Col xs={12} sm={6}>
              {viewReceipt()}
              <Button
                sx={{ height: 33, fontSize: buttonFont }}
                variant="contained"
                onClick={() => {
                  setReceiptView(true);
                }}
              >
                View Receipt
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              {Terms()}
              <Button
                sx={{ height: 33, fontSize: 15 }}
                variant="contained"
                onClick={() => {
                  setTermsOpen(true);
                }}
              >
                View Terms and Conditions
              </Button>
            </Col>
          </Row>
          <Row xs={12}>
            <Col xs={4} md={4}>
              <TextField
                id="TotalPieces"
                label="Total Pieces"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                fullWidth
                disabled
                value={receipt?.TotalPieces}
              />
            </Col>
            <Col xs={4} md={4}>
              <TextField
                id="TotalCubic"
                label="Total Cubic"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                fullWidth
                disabled
                value={receipt?.TotalCubic}
              />
            </Col>
            <Col xs={4} md={4}>
              <TextField
                id="TotalWeight"
                label="Total Weight"
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                disabled
                value={receipt?.TotalWeight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">lbs</InputAdornment>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row xs={12}>
            <Col xs={12} md={4}>
              <TextField
                id="AdditionalInsurance"
                label="Additional Insurance"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                fullWidth
                disabled
                value={receipt?.AdditionalInsurance}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col xs={12} md={4} style={{ textAlign: "left" }}>
              <FormControlLabel
                sx={{ paddingTop: 1 }}
                control={
                  <Checkbox
                    id="AcceptInsurance"
                    onChange={onCheckBoxChange}
                    value={receipt?.AcceptInsurance}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                  />
                }
                label="Accept Additional Insurance"
              />
            </Col>
          </Row>
          <Row xs={12}>
            <Col xs={6} md={6}>
              <TextField
                id="TotalCharge"
                label="Total Charge"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                fullWidth
                disabled
                value={receipt?.TotalCharge}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col xs={6} md={6}>
              <TextField
                id="Collect"
                label="Collect"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                fullWidth
                error
                disabled
                value={receipt?.Collect}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row
            sm={12}
            style={{
              paddingTop: 12,
              zIndex: 2000,
              display: saving ? "none" : "block",
            }}
          >
            <Col style={{ textAlign: "center" }}>
              <Button
                sx={{ height: 30, fontSize: 18 }}
                variant="text"
                onClick={clearSig}
              >
                Clear Signature
              </Button>
              <br />
              {receipt?.Signature ?
                <img alt="" src={"data:image/png;base64," + receipt?.Signature} /> :

                <SignatureCanvas
                  backgroundColor="rgba(235, 235, 235, 1)"
                  ref={sigCanvas}
                  penColor="darkblue"
                  canvasProps={{ height: 300, width: screenWidth - 10 }}
                />
              }
            </Col>
          </Row>
        </div>
      </>
    );
  }
  function Complete() {
    return (
      <>
        <div>
          <Row sm={12} style={{ padding: 100 }}></Row>
          <Row sm={12} style={{ padding: 50 }}>
            <Col style={{ textAlign: "center" }}>
              <FontAwesomeIcon
                color="#4caf50"
                icon={faCircleCheck}
                size={"8x"}
                onClick={() => {
                  window.location.reload();
                }}
              />
            </Col>
          </Row>
          <Row xs={12} style={{ padding: 10 }} className={"justify-center"}>
            <Col style={{ textAlign: "center" }}>
              <Row>
                <Col xs={12}>
                  <div>
                    <strong>RECEIPT COMPLETE</strong>
                    <br />
                    Thank you, your receipt is confirmed. <br />
                    Please keep this order number for your records:
                    <br />
                    {receipt?.ReceiptNo}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  function viewReceipt() {
    return (
      <>
        <Dialog
          open={receiptViewOpen}
          fullScreen
          onClose={() => {
            setReceiptView(false);
          }}
          scroll={scroll}
          aria-labelledby="receiptTitle"
          aria-describedby="receiptSummary"
        >
          <DialogTitle id="receiptTitle">View Receipt </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText id="receiptSummary" tabIndex={-1}>
              <Row>
                <Col>
                  <Row>
                    <strong>Shipper Information</strong>
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {" "}
                    {receipt?.ShipFirstName} {receipt?.ShipLastName}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ShipAdd1} {receipt?.ShipAdd2}{" "}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>{receipt?.ShipAdd2} </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ShipCity}, {receipt?.ShipProv}{" "}
                    {receipt?.ShipPostal}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ShipPhone} | {receipt?.ShipEmail}
                  </Row>
                </Col>

                <Col>
                  <Row>
                    <strong>Consignee Information</strong>
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ConFirstName} {receipt?.ConLastName}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ConAdd1} {receipt?.ConAdd2}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>{receipt?.ConAdd2}</Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ConCity} {receipt?.ConCountry}{" "}
                  </Row>
                  <Row style={{ paddingLeft: 12 }}>
                    {receipt?.ConPhone} {receipt?.ConEmail}
                  </Row>
                </Col>
              </Row>
              <br />
              {detailGrid.map((det, idx) => {
                return (
                  <Row>
                    <strong>Detail Row {idx + 1}</strong>
                    <Row>
                      <Col xs={3} md={1}>
                        SealNo.: <strong>{det?.SealNo} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Quantity: <strong>{det?.Quantity} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Length: <strong>{det?.Length} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Width: <strong>{det?.Width} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Height: <strong>{det?.Height} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Cubic: <strong>{det?.Cubic} </strong>
                      </Col>
                      <Col xs={3} md={1}>
                        {" "}
                        Weight: <strong>{det?.Weight} </strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Col>
                          {" "}
                          Description: <strong>{det?.Desc} </strong>
                        </Col>
                      </Col>
                    </Row>
                  </Row>
                );
              })}
              <br />
              <Row>
                <strong>Pricing</strong>
                <Row>
                  <Col>
                    Freight Charge: <strong>{receipt?.Freight} </strong>{" "}
                  </Col>
                  <Col>
                    Pickup Charge: <strong>{receipt?.Pickup}</strong>{" "}
                  </Col>
                  <Col>
                    Total Cost: <strong>{receipt?.TotalCharge} </strong>{" "}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Additional Insurnace:{" "}
                    <strong>{receipt?.AdditionalInsurance} </strong>{" "}
                  </Col>
                  <Col>
                    Collect: <strong>{receipt?.Collect} </strong>{" "}
                  </Col>
                  <Col>
                    Prepaid: <strong>{receipt?.Prepaid} </strong>{" "}
                  </Col>
                </Row>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setReceiptView(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  //#endregion

  function Terms() {
    return (
      <>
        <Dialog
          open={termsOpen}
          maxWidth={"xl"}
          onClose={() => {
            setTermsOpen(false);
          }}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Row>
                <Col>
                  - Name must be entered correctly.{" "}
                  <strong>$40 for name changes </strong>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  - Insurance covers TOTAL LOSS ONLY Maximum liability is
                  S250. Additional insurance coverage is the responsibility of
                  the shipper
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  - Maximum barrel weight: <strong>250 lbs</strong>
                  <br />- Maximum box weight under 5 cuft:{" "}
                  <strong>50 lbs</strong>
                  <br />- Any cargo over these limits are subject to surcharge
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  - Tennessee Intl will put a hold on all cargo until{" "}
                  <strong>FULL Payment</strong>
                  <br />- We assume no liability for personal injury upon
                  delivery or loading of cargo
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <strong style={{ color: "#d12124" }}>
                    Tennessee international is not responsible for loss. damages
                    or delays to freight listed, due to unforeseen
                    circumstances.
                  </strong>
                </Col>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTermsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setTermsOpen(false);
                setAgreeTerms(true);
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  //#endregion

  //#region Navigation
  async function nextStep() {
    let valid = true;
    switch (activeStep) {
      case 0:
        valid = validateShipping();
        break;
      case 1:
        valid = validateConsignee();
        break;
      case 2:
        valid = validateDetails();
        break;
      case 3:
        valid = validateReview();
        break;
      case 4:
        valid = validateSign();
        break;
    }
    if (receipt && valid) {
      if (activeStep === 1 || activeStep === 3) {

        let id = undefined;;
        setSaving(true);
        try {
          await upsertReceipt(receipt).then(async (r) => {
            if (r) {
              setReceipt({ ...receipt, "ReceiptId": r });
              receipt.ReceiptId = r;
              id = r;
            }
          });
        } catch (ex) {
          setSaving(false);
          setMessage({ type: "error", message: JSON.stringify(ex) });
        }

        if (activeStep === 1 && props.info.type === Mode.Customer && id) {

          if (detailGrid.length > 0) {
            setDetail(detailGrid[0]);
          }
          else {
            let detail: IDetail = {
              id: "",
              ReceiptId: id!,
              Name: receipt?.ReceiptNo + "-1",
              Quantity: 1,
              Desc: "FOOD STUFF & USED CLOTHING",
              Length: 0,
              Width: 0,
              Height: 0,
              Cubic: 0,
              Weight: 0,
              SealNo: "",
            };
            let dataUri = await QRCode.toDataURL("https://pickup.tennesseeinternational.ca/?ReceiptLine=" + detail.Name);
            dataUri = dataUri.replace("data:", "").replace("base64", "");
            let qrCode = dataUri.split(",");
            detail.QRCode = qrCode[1] ? qrCode[1] : "";
            detail.MimeType = qrCode[0].replace(";", "");
            setDetail(detail);
            setReceipt({ ...receipt, "TotalPieces": 1 });
          }
        }

        if (activeStep < 5) setActiveStep(activeStep + 1);
        setSaving(false);

      }
      else {
        if (activeStep < 5) setActiveStep(activeStep + 1);
      }
    }
  }

  function prevStep() {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  }

  async function receiptComplete() {
    // setComplete(true)
    if (receipt) {
      let dataUri = sigCanvas.current?.toDataURL();
      if (dataUri) {
        const event = new Date();
        const options: any = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        dataUri = dataUri.replace("data:", "");
        let signature = dataUri.split(",");
        receipt.AgreeTerms = true;
        receipt.Status = 40;
        receipt.SignatureDate = event.toLocaleDateString('en-CA', options);
        receipt.Signature = signature[1] ? signature[1] : "";
        receipt.MimeType = signature[0]
          ? signature[0].replace(";base64", "")
          : "";
      } else if (receipt.Signature) {
        receipt.AgreeTerms = true;
        receipt.Status = 40;
      }
      setSaving(true);
      setMessage({
        message: "Generating Receipt. This may take up to 1 minute.",
        type: "warning",
      });
      try {
        receipt.Type = info.type === Mode.Customer ? "customer" : "pickup";
        await upsertReceipt(receipt).then((r) => {
          setTimeout(() => {
            setMessage(undefined);
            setComplete(true);
            setSaving(false);
          }, 800);
        });
      } catch (ex) {
        setAdding(false);
        setMessage({ type: "error", message: JSON.stringify(ex) });
      }
    }
  }
  async function receiptCompleteCustomer() {
    const event = new Date();
    const options: any = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    if ((detail?.Quantity && detail?.Quantity < 1) || detail?.Desc === "") {
      setMessage({
        message: "Quantity and Description are required.",
        type: "error",
      });
      return;
    }
    if (receipt) {
      setSaving(true);
      setMessage({
        message: "Generating Receipt. This may take up to 1 minute.",
        type: "warning",
      });
      try {
        let dataUri = sigCanvas.current?.toDataURL();
        if (dataUri) {
          dataUri = dataUri.replace("data:", "");
          let signature = dataUri.split(",");
          receipt.AgreeTerms = true;
          receipt.SignatureDate = event.toLocaleDateString('en-CA', options);
          receipt.Status = 40;
          receipt.Signature = signature[1] ? signature[1] : "";
          receipt.MimeType = signature[0] ? signature[0].replace(";base64", "") : "";
        } else if (receipt.Signature) {
          receipt.AgreeTerms = true;
          receipt.Status = 40;
        }
        receipt.Type = info.type === Mode.Customer ? "customer" : "pickup";
        receipt.Status = 20;
        await upsertReceipt(receipt).then((r) => {
          if (r) {
            receipt.ReceiptId = r.ReceiptId
          }
        });

        if (detail) {
          await upsertDetail(detail).then((r) => {
            if (r) {
              if (activeStep < 5) setActiveStep(activeStep + 1);
              setSaving(false);
              setComplete(true);
              setSaving(false);
              setTimeout(() => {
                setMessage(undefined);
              }, 800);
            }
          });
        }
      } catch (ex) {
        setAdding(false);
        setMessage({ type: "error", message: JSON.stringify(ex) });
      }
    }
  }

  //#endregion

  function getStepComponent() {
    switch (activeStep) {
      case 0:
        return Shippers();
      case 1:
        return Consignee();
      case 2:
        return props.info.type === Mode.Customer ? DetailsCustomer() : Details();
      case 3:
        return Review();
      case 4:
        return Sign();
      case 5:
        return Complete();
    }
  }


  return !complete ? (
    <React.Fragment>
      <div className="mainForm">
        <Row className="justify-content-center" md={12}>
          <Col sm={12}>
            <div>
              <Stepper activeStep={activeStep}>
                {isMobile ? (
                  <Step sx={{ height: "90%" }} key={activeStep}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label": {
                          fontSize: "20px",
                        },
                      }}
                      icon={
                        activeStep < 5 ? (
                          icons(activeStep)
                        ) : (
                          <FontAwesomeIcon
                            color={"#4caf50"}
                            icon={faCheckCircle}
                            size={"2x"}
                          />
                        )
                      }
                    >
                      {steps[activeStep]}
                    </StepLabel>
                  </Step>
                ) : (
                  steps.map((label, index) => {
                    return (
                      <Step sx={{ height: "90%" }} key={index}>
                        <StepLabel
                          sx={{
                            "& .MuiStepLabel-label": {
                              fontSize: "20px",
                            },
                          }}
                          icon={
                            activeStep <= index ? (
                              icons(index)
                            ) : (
                              <FontAwesomeIcon
                                color={"#4caf50"}
                                icon={faCheckCircle}
                                size={"2x"}
                              />
                            )
                          }
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })
                )}
                <span className="MuiStepLabel-label">
                  Receipt No.<br /> {receipt?.ReceiptNo}
                </span>
              </Stepper>
            </div>
          </Col>
        </Row>
        <Row sm={12}>{getStepComponent()}</Row>
        <Row className="justify-content-center">
          <Col
            xs={12}
            sm={8}
            style={{ display: message ? "block" : "none", textAlign: "center" }}
          >
            <br />
            <Alert severity={message?.type} sx={{ fontSize: 16 }}>
              <AlertTitle>{message?.message}</AlertTitle>
            </Alert>
          </Col>
        </Row>
        <Row xs={12} style={{ paddingTop: 20, paddingBottom: 20, zIndex: 0 }}>
          <Col>
            <Box
              sx={{
                m: 1,
                position: "relative",
                display: activeStep === 0 ? "none" : "block",
              }}
            >
              <Button
                sx={{ height: 50, fontSize: buttonFont }}
                fullWidth
                variant="contained"
                onClick={prevStep}
                hidden={activeStep === 0}
                disabled={saving}
              >
                Previous
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Col>
          <Col>
            <FormControlLabel
              hidden={activeStep !== 4}
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
                  checked={agreeTerms}
                  onChange={() => {
                    setAgreeTerms(!agreeTerms);
                  }}
                />
              }
              label="I agree to the terms and conditions"
            />
          </Col>
          <Col>
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                sx={{
                  height: 50,
                  fontSize: buttonFont,
                  display:
                    info.type === Mode.Customer && activeStep === 2
                      ? "block"
                      : "none",
                }}
                fullWidth
                variant="contained"
                onClick={receiptCompleteCustomer}
                hidden={
                  info.type === Mode.Customer && activeStep === 2 ? false : true
                }
                disabled={agreeTerms === false || saving}
              >
                Complete
              </Button>
              <Button
                sx={{
                  height: 50,
                  fontSize: buttonFont,
                  display: activeStep !== 4 ? "none" : "block",
                }}
                fullWidth
                variant="contained"
                onClick={receiptComplete}
                hidden={activeStep !== 4 ? true : false}
                disabled={agreeTerms === false || saving}
              >
                Complete
              </Button>
              <Button
                sx={{ height: 50, fontSize: buttonFont }}
                fullWidth
                variant="contained"
                onClick={nextStep}
                hidden={
                  (activeStep === 4 ? true : false) ||
                  (info.type === Mode.Customer && activeStep === 2
                    ? true
                    : false)
                }
                disabled={saving}
              >
                &nbsp;&nbsp;Next&nbsp;&nbsp;
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>{Complete()}</React.Fragment>
  );
};

export default Receipt;
