import { library } from "@fortawesome/fontawesome-svg-core";
import { faCirclePlus, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  AlertTitle,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  generateOrderNumber,
  getDetails,
  searchReceipt,
  upsertReceipt,
} from "../common/DataLayer";
import { IEntityParam, IMessage, Mode } from "../common/Interfaces";
import { useStoreActions, useStoreState } from "../common/model/Store";
import NewReceipt from "./Receipt";

const Home = (props: { info: IEntityParam }) => {
  let { info } = props;
  const [found, setFound] = useState(false);
  const [searching, setSearching] = useState(false);

  const [orderNo, setOrderNo] = useState("");
  const [orderNoMessage, setOrderNoMessage] = useState<IMessage | undefined>(
    undefined
  );

  const user = useStoreState((state) => state.currentUser);
  const setCurrentUser = useStoreActions((state) => state.setCurrentUser);

  useEffect(() => {
    let oNumber = generateOrderNumber();
    oNumber = oNumber.slice(0, 10);
    setOrderNo(oNumber);
    if (info.id) {
      getOrderNo();
    }
  }, []);

  async function getOrderNo() {
    setOrderNoMessage(undefined);
    let order = "";
    if (info.id) {
      order = info.id;
    } else if (!orderNo && orderNo.length !== 16) {
      setSearching(false);
      setOrderNoMessage({
        type: "error",
        message: "Receipt number must be 16 digits",
      });
      return;
    } else {
      order = orderNo;
    }
    setSearching(true);
    searchReceipt(order).then(async (rec) => {
      if (rec && rec.ReceiptId) {
        await getDetails(rec.ReceiptId).then((det) => {
          if (det && det.length > 0) info.detailGrid = det;
          else info.detailGrid = [];
        });
        setSearching(false);
        setOrderNoMessage({ type: "success", message: "Receipt number found" });
        setTimeout(() => {
          info.id = rec?.ReceiptId;
          info.receipt = rec;
          setFound(true);
        }, 1000);
      } else {
        setOrderNoMessage({ type: "error", message: "Receipt not found" });
      }

      setSearching(false);
    });
  }
  async function addReceipt() {
    info.id = undefined;
    info.receipt = {
      ReceiptId: null,
      ReceiptNo: generateOrderNumber(),
      ReceiptDate: new Date().toISOString(),
      ShipFirstName: "",
      ShipLastName: "",
      ShipEmail: "",
      ShipPhone: "",
      ShipAdd1: "",
      ShipAdd2: "",
      ShipCity: "",
      ShipProv: "",
      ShipPostal: "",
      ShipCountry: "CANADA",

      ConFirstName: "",
      ConLastName: "",
      ConEmail: "",
      ConPhone: "",
      ConAdd1: "",
      ConAdd2: "",
      ConCity: "",
      ConProv: "",
      ConPostal: "",
      ConCountry: "GUYANA",
      PickupDriver: "",
      DoorToDoor: false,
      TotalPieces: 0,
      TotalCubic: 0,
      TotalWeight: 0,

      Pickup: 0,
      Freight: 0,
      TotalCharge: 0,
      Collect: 0,
      Prepaid: 0,
      AdditionalInsurance: 0,
      AcceptInsurance: false,
      Status: 10,
      Signature: "",
      Type: info.type === Mode.Customer ? "customer" : "pickup",
    };
    info.detailGrid = [];
    setFound(true);
  }

  async function regenerateReceipt() {
    setOrderNoMessage(undefined);
    let order = "";
    if (info.id) {
      order = info.id;
    } else if (!orderNo && orderNo.length !== 16) {
      setSearching(false);
      setOrderNoMessage({
        type: "error",
        message: "Receipt number must be 16 digits",
      });
      return;
    } else {
      order = orderNo;
    }
    setSearching(true);
    searchReceipt(order).then(async (rec) => {
      if (rec && rec.ReceiptId) {
        await getDetails(rec.ReceiptId).then((det) => {
          if (det && det.length > 0)
            rec.detailGrid = det;
          else info.detailGrid = [];
        });
        rec.id = rec?.ReceiptId;
        rec.receipt = rec;
        rec.mimetype = "image/png";
        setOrderNoMessage({ type: "success", message: "Receipt number found" });
        rec.Type = info.type === Mode.Customer ? "customer" : "pickup";
        await upsertReceipt(rec).then((r) => {
          setTimeout(() => {
            setOrderNoMessage({ type: "success", message: "Regenerated Receipt" });

          }, 800);
        });

        setTimeout(() => {

        }, 5000);
      } else {
        setOrderNoMessage({ type: "error", message: "Receipt not found" });
      }

      setSearching(false);
    });
  }

  const orderNoChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderNo(event.target.value);
  };
  function getControl() {
    if (!found) {
      return (
        <React.Fragment>
          <div className="mainForm">
            <Row xs={12} style={{ textAlign: "center", padding: 15 }}>
              <Col xs={12}>
                <Paper elevation={7}>
                  <Typography fontSize={18} fontWeight={10}>
                    Tennessee International Freight <br />
                    {info.type === Mode.Customer ? "Customer" : "Pickup"}{" "}
                    Receipt For Shipment
                  </Typography>
                </Paper>
              </Col>
            </Row>
            <br />
            {info.type === Mode.Pickup ? (
              <Row xs={12} style={{ padding: 10, display: searching ? "none" : "block" }}>
                <Col xs={12} style={{ textAlign: "center" }}>
                  <TextField
                    id="orderNo"
                    label="Existing Order Number"
                    variant="filled"
                    margin="normal"
                    fullWidth
                    value={orderNo}
                    onChange={orderNoChanged}
                  />
                  <IconButton aria-label="search">
                    <FontAwesomeIcon
                      color="#d12124"
                      icon={faSearch}
                      size={"6x"}
                      onClick={() => {
                        getOrderNo();
                      }}
                    />
                  </IconButton>
                  {/* {
                    info.type === Mode.Pickup ? <IconButton aria-label="regenerate receipt">
                      <FontAwesomeIcon
                        color="#d12124"
                        icon={faPrint}
                        size={"6x"}
                        onClick={() => {
                          regenerateReceipt();
                        }}
                      />
                    </IconButton> : ""

                  } */}

                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row
              className="justify-content-center"
              sm={12}
              style={{ paddingTop: 10 }}
            >
              <Col xs={1} md={1}></Col>

              <Col
                xs={12}
                md={8}
                style={{
                  display: orderNoMessage ? "block" : "none",
                  textAlign: "center",
                }}
              >
                <Alert severity={orderNoMessage?.type} sx={{ fontSize: 20 }}>
                  <AlertTitle>{orderNoMessage?.message}</AlertTitle>
                </Alert>
              </Col>
              <Col
                style={{
                  display: searching ? "block" : "none",
                  textAlign: "center",
                }}
              >
                <LinearProgress />
              </Col>
              <Col xs={1} md={1}></Col>
            </Row>
            {!info.id ? (
              <Row sm={12} style={{ padding: 50, display: searching ? "none" : "block" }}>
                <Col style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    color="#d12124"
                    icon={faCirclePlus}
                    size={"8x"}
                    onClick={addReceipt}
                  />
                </Col>
              </Row>
            ) : (
              <Row sm={12} style={{ padding: 50, display: searching ? "block" : "none" }}>
                <Col style={{ textAlign: "center" }}>
                  <Alert severity={"info"} sx={{ fontSize: 20 }}>
                    <AlertTitle>Loading Receipt</AlertTitle>
                  </Alert>
                </Col>
              </Row>
            )}

            {/* <Row sm={12} style={{ padding: 50 }}>
                        <Col style={{ textAlign: "center" }}>
                            <input type="file" name="file" onChange={changeHandler} />
                            <FontAwesomeIcon color='#d12124' icon={faCirclePlus} size={"8x"} onClick={addReceipt} />
                        </Col>
                    </Row> */}
          </div>
        </React.Fragment>
      );
    } else {
      return <NewReceipt info={info} />;
    }
  }
  const changeHandler = async (event: any) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      if (reader.result) {
        const fle = reader.result.toString().split(",");
        let type = fle[0].split(";");
        const mime = type[0].replace("data:", "");
        const b64 = fle[1];
        const json = {
          FirstName: "Ryan",
          LastName: "Ramdewar",
          MimeType: mime,
          Content: b64,
        };
        try {
          let ret = await fetch(
            "https://prod-06.canadacentral.logic.azure.com:443/workflows/706b8fbb33af4566951049acd0c05bd1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lukjoRqGpftXBIDXxn7SUHs1p_VfYZlQigxdS4xXcxM",
            {
              method: "POST",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(json),
            }
          ).then(async (r) => {
            return await r.json();
          });
          return ret.ReceiptId;
        } catch (e) {
          throw e;
        }
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return getControl();
};

export default Home;
