import { action, Action, createTypedHooks, thunk, Thunk } from 'easy-peasy';
import { getRoleNames } from '../BusinessLogic';

import {
    ILoadSheet,
    IUser,
} from '../Interfaces';
import { searchLoadSheet } from '../DataLayer';


const typedHooks = createTypedHooks<IStoreModel>();
export const useStoreState = typedHooks.useStoreState;
export const useStoreActions = typedHooks.useStoreActions;

export interface IStoreModel {
    currentUser: IUser | undefined,
    setCurrentUser: Action<IStoreModel, IUser>,
    setCurrentUserRoles: Action<IStoreModel, string[]>,
}

export const initStoreModel: IStoreModel = {
    currentUser: undefined,
    setCurrentUser: action((state, payload) => {
        console.log("CurrentUser: " + JSON.stringify(payload))
        state.currentUser = payload;
    }),
    setCurrentUserRoles: action((state, payload) => {
        console.log("CurrentUser: " + JSON.stringify(payload))
        if (state.currentUser)
            state.currentUser.roles = payload;
    }),
}