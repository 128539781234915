import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createStore, StoreProvider } from 'easy-peasy';

import { getAppMode } from './common/BusinessLogic';
import { Mode } from './common/Interfaces';
import { initStoreModel } from './common/model/Store';
import { createTheme, ThemeProvider } from '@mui/material';
import Home from './components/Home';
import LoadSheet from './components/LoadSheet';

function App() {
  // Create Store
  const store = createStore(initStoreModel);
  let entityParams = getAppMode();
  let theme = createTheme({
    palette: {
      primary: {
        main: '#d12124',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      info: {
        main: theme.palette.secondary.main,
      },
    },
  });
  return (
    <StoreProvider store={store} >
      <ThemeProvider theme={theme}>
        {
          entityParams.type === Mode.LoadSheetEdit ?
            <LoadSheet info={entityParams} /> :
            entityParams.type === Mode.LoadSheet || entityParams.type === Mode.LoadSheetSearch ?
              <LoadSheet info={entityParams} /> :
              <Home info={entityParams} />
        }
      </ThemeProvider>
    </StoreProvider>
  );
}
export default App;
